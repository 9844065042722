/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

export default function ListAllFuneral() {
  const [funeralHome, setFuneralHome] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchPeople = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL_SERVER}/getAllFuneralHomes/`
        );
        setFuneralHome(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPeople();
  }, []);

  return (
    <div className="bg-white py-10 sm:py-10 mb-60">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <div className="border-b border-gray-900/10 pb-12">
            <div className="mt-10">
              <div className=""></div>
              <p
                aria-current="page"
                className="font-medium text-gray-500 hover:text-gray-600"
                          >
                              {t('allfuneralhomes')}
              </p>

              <ul role="list" className="divide-y divide-gray-100">
                {funeralHome.length > 0 ? (
                  funeralHome.map((funeral) => (
                    <li
                      key={funeral.email}
                      className="flex justify-between gap-x-6 py-5"
                    >
                      <div className="flex min-w-0 gap-x-4">
                        <img
                          className="h-12 w-12 flex-none rounded-full bg-gray-50"
                          src={funeral.photo}
                          alt=""
                        />
                        <div className="min-w-0 flex-auto">
                          <p className="text-sm font-semibold leading-6 text-gray-900">
                            {funeral.name}
                          </p>
                          <p className="mt-1 truncate text-xs leading-5 text-gray-500 text-left">
                            {t("login.email")}: {funeral.email}
                          </p>
                          <p className="mt-1 truncate text-xs leading-5 text-gray-500 text-left">
                            {t("registerFuneralHome.createaccount.phone")}:{" "}
                            {funeral.phone}
                          </p>
                        </div>
                      </div>

                      <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                        <p className="text-xs leading-5 text-gray-500">
                          {funeral.city}, {funeral.district}, {funeral.country}
                        </p>
                      </div>
                    </li>
                  ))
                ) : (
                  <li>{t("list-funerals.nofunerals")}</li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
