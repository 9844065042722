import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "../../Dialog/ConfirmationDialog";

const EditPersonCelebration = () => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [photo, setPhoto] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [parish, setParish] = useState("");
  const [funeralDetails, setFuneralDetails] = useState({
    celebration: "",
    church: {
      address: "",
      time: "",
      day: "",
    },
    funeral: {
      address: "",
      time: "",
      day: "",
    },
    funeral_services: "",
    additional_info: "",
  });
  const [birthday, setBirthday] = useState("");
  const [death, setDeath] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false); // State for showing confirmation dialog

  useEffect(() => {
    const fetchPerson = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL_SERVER}/getPersonbyId/${id}`
        );
        if (response.ok) {
          const data = await response.json();
          setName(data.name);
          setParish(data.parish);
          setCountry(data.country);
          setCity(data.city);
          setPhoto(data.photo);
          setDistrict(data.district);
          setBirthday(data.birthday);
          setDeath(data.death);
          setDescription(data.description);
          setFuneralDetails(data.funeralDetails);
        } else {
          throw new Error("Failed to fetch person");
        }
      } catch (error) {
        console.error("Error fetching person:", error);
        setError(error.message);
      }
    };

    fetchPerson();
  }, [id]);

  const handleRegister = async (e) => {
    e.preventDefault();
    setShowConfirmation(true); // Show confirmation dialog
  };
  const handleSubmit = async (e) => {
    setShowConfirmation(false); // Close confirmation dialog

    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const token = user.stsTokenManager.accessToken;
      const uid = user.uid;
      // Get the download URL for the image

      const response = await fetch(
        `${process.env.REACT_APP_URL_SERVER}updatePerson/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id,
            name,
            photo,
            country,
            city,
            district,
            idFuneralHome: uid,
            funeralDetails,
            death,
            birthday,
            parish,
            description,
          }),
        }
      );

      if (response.ok) {
        // Redirect the user to the desired page after successful update
        navigate(`/person/${id}`);
      } else {
        throw new Error("Failed to update person.");
      }
    } catch (error) {
      console.error("Error updating person:", error.message);
      setError(error.message);
    }
  };

  return (
    <div className="mx-auto max-w-2xl p-6 bg-white">
      <ConfirmationDialog
        open={showConfirmation}
        setOpen={setShowConfirmation}
        confirmAction={handleSubmit}
        title={t("editcelebration.modal.title")}
        subtile={t("editcelebration.modal.subtitle")}
        buttonText={t("editcelebration.modal.button")}
      />
      <h2 className="mt-10 text-2xl font-bold mb-4 text-center">
        {t("editcelebration.edit.title")}
      </h2>
      <form onSubmit={handleRegister} className="">
        <p className="mt-1 text-sm leading-6 text-gray-600">
          {t("editcelebration.edit.title.info1")}
        </p>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          {t("editcelebration.edit.title.info2")}
        </p>
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-full">
            <label
              htmlFor="username"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("registerF.personal-info.name")}
            </label>
            <div className="mt-2">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input
                  type="text"
                  name="name"
                  id="name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  readOnly
                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-full">
            <label
              htmlFor="church-day"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("editcelebration.edit.celebration")}
            </label>
            <div className="mt-2">
              <select
                name="celebration"
                id="celebration"
                value={funeralDetails.celebration}
                onChange={(e) =>
                  setFuneralDetails({
                    ...funeralDetails,
                    celebration: e.target.value,
                  })
                }
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option value="">
                  {" "}
                  {t("register.personal-info.service-typeselect")}
                </option>
                <optgroup label={t("register.personal-info.cristianservices")}>
                  <option value={t("register.personal-info.burialmass")}>
                    {t("register.personal-info.burialmass")}
                  </option>
                  <option value={t("register.personal-info.sevendaymass")}>
                    {t("register.personal-info.sevendaymass")}
                  </option>
                  <option value={t("register.personal-info.mensalmass")}>
                    {t("register.personal-info.mensalmass")}
                  </option>
                  <option value={t("register.personal-info.yearmass")}>
                    {" "}
                    {t("register.personal-info.yearmass")}
                  </option>
                  <option value={t("register.personal-info.masspresentbody")}>
                    {t("register.personal-info.masspresentbody")}
                  </option>
                  <option value="Câmara Ardente">Câmara Ardente</option>
                  <option value="Celebração da Palavra">
                    Celebração da Palavra
                  </option>

                  {/* Add more Christian options here */}
                </optgroup>
                <optgroup label={t("register.personal-info.other")}>
                  <option value=""> </option>
                  <option value="Velório"> Velório </option>
                  <option value="Culto de Memória">Culto de Memória</option>
                  <option value="Funeral Service">Funeral Service</option>
                  <option value="Memorial Service">Memorial Service</option>
                  <option value="Shiva">Shiva</option>
                  <option value="Kaddish">Kaddish</option>
                  <option value="Salat al-Janazah">Salat al-Janazah</option>
                  <option value="Buddhist Funeral">Buddhist Funeral</option>
                  <option value="Hindu Antim Sanskar">
                    Hindu Antim Sanskar
                  </option>
                  {/* Add more options for other religions */}
                </optgroup>
              </select>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label
              htmlFor="church-address"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("register.personal-info.service-day")}
            </label>
            <div className="mt-2">
              <input
                type="date"
                name="church-address"
                id="church-address"
                value={funeralDetails.church.day}
                onChange={(e) =>
                  setFuneralDetails({
                    ...funeralDetails,
                    church: {
                      ...funeralDetails.church,
                      day: e.target.value,
                    },
                  })
                }
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label
              htmlFor="church-time"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("register.personal-info.service-hour")}
            </label>
            <div className="mt-2">
              <input
                type="time"
                name="church-time"
                id="church-time"
                value={funeralDetails.church.time}
                onChange={(e) =>
                  setFuneralDetails({
                    ...funeralDetails,
                    church: {
                      ...funeralDetails.church,
                      time: e.target.value,
                    },
                  })
                }
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-full">
            <label
              htmlFor="church-day"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("register.personal-info.service-address")}
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="church-day"
                id="church-day"
                value={funeralDetails.church.address}
                onChange={(e) =>
                  setFuneralDetails({
                    ...funeralDetails,
                    church: {
                      ...funeralDetails.church,
                      address: e.target.value,
                    },
                  })
                }
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          {/*   <div className="sm:col-span-full">
            <label
              htmlFor="funeral-services"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("register.personal-info.service-edital")}
            </label>
            <div className="mt-2">
              <textarea
                name="funeral-services"
                id="funeral-services"
                value={funeralDetails.funeral_services}
                onChange={(e) =>
                  setFuneralDetails({
                    ...funeralDetails,
                    funeral_services: e.target.value,
                  })
                }
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              ></textarea>
            </div>
          </div> */}
          <div className="sm:col-span-full">
            <label
              htmlFor="additional-info"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("register.personal-info.service-aditional")}
            </label>
            <div className="mt-2">
              <textarea
                name="additional-info"
                id="additional-info"
                value={funeralDetails.additional_info}
                onChange={(e) =>
                  setFuneralDetails({
                    ...funeralDetails,
                    additional_info: e.target.value,
                  })
                }
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              ></textarea>
            </div>
          </div>
        </div>

        {error && <div className="text-red-500 mb-4 col-span-2">{error}</div>}
        <button
          type="submit"
          className="mt-8 flex w-full justify-center rounded-md bg-[#384C67]  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-20"
        >
          {t("funeralHome.update")}
        </button>
      </form>
    </div>
  );
};

export default EditPersonCelebration;
