import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Region = ({ country, district, city, parish, onChange, putParish }) => {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(country || "");

  const [selectedDistrict, setSelectedDistrict] = useState(district || "");
  const [selectedCity, setSelectedCity] = useState(city || "");
  const [selectedParish, setSelectedParish] = useState(parish || "");
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [parishes, setParishes] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(
          "https://secure.geonames.org/countryInfoJSON?username=pdm2024"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch countries");
        }
        const data = await response.json();
        setCountries(data.geonames);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchRegionData = async () => {
      if (!selectedCountry) return;

      try {
        const response = await fetch(
          `https://secure.geonames.org/searchJSON?q=${selectedCountry}&maxRows=1&username=pdm2024`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch country data");
        }
        const data = await response.json();
        const countryCode = data?.geonames?.[0]?.countryCode || "";
        const districtResponse = await fetch(
          `https://secure.geonames.org/searchJSON?country=${countryCode}&featureCode=ADM1&maxRows=200&username=pdm2024`
        );
        if (!districtResponse.ok) {
          throw new Error("Failed to fetch districts");
        }
        const districtData = await districtResponse.json();
        setDistricts(districtData.geonames || []);
      } catch (error) {
        console.error("Error fetching region data:", error);
      }
    };

    fetchRegionData();
  }, [selectedCountry]);

  useEffect(() => {
    const fetchCities = async () => {
      if (!selectedDistrict) return;

      try {
        const district = districts.find((d) => d.name === selectedDistrict);
        if (!district) return;
        const response = await fetch(
          `https://secure.geonames.org/searchJSON?country=${district.countryCode}&adminCode1=${district.adminCode1}&featureCode=ADM2&maxRows=200&username=pdm2024`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch cities");
        }
        const data = await response.json();
        setCities(data?.geonames || []);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };

    fetchCities();
  }, [selectedDistrict, districts]);

  useEffect(() => {
    const fetchParishes = async () => {
      if (!selectedDistrict) return;

      try {
        // Find the selected city object based on the selected district
        const city = cities.find((d) => d.name === selectedCity);
        if (!city) return; // Return if city is not found
        const cityGeonameId = city.geonameId;
        const response = await fetch(
          `https://secure.geonames.org/childrenJSON?geonameId=${cityGeonameId}&username=pdm2024`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch parishes");
        }
        const data = await response.json();
        setParishes(data.geonames || []);
      } catch (error) {
        console.error("Error fetching parishes:", error);
      }
    };

    fetchParishes();
  }, [selectedDistrict, selectedCity, cities]);

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    setSelectedDistrict("");
    setSelectedCity("");
    setSelectedParish("");
    onChange("country", event.target.value);
  };

  const handleDistrictChange = (event) => {
    setSelectedDistrict(event.target.value);
    setSelectedCity("");
    setSelectedParish("");
    onChange("district", event.target.value);
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    setSelectedParish("");
    onChange("city", event.target.value);
  };

  const handleParishChange = (event) => {
    setSelectedParish(event.target.value);
    onChange("parish", event.target.value);
  };

  return (
    <div>
      <div className="sm:col-span-3 grid grid-cols-2 gap-x-4">
        <div>
          <label
            htmlFor="country"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {t("registerF.personal-info.country")}
          </label>
          <div className="mt-2">
            <select
              name="country"
              required
              value={selectedCountry}
              onChange={handleCountryChange}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-10"
            >
              <option value="">Select a country</option>
              {countries.map((country) => (
                <option key={country.countryCode} value={country.countryName}>
                  {country.countryName}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div>
          <label
            htmlFor="district"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {t("registerF.personal-info.district")}
          </label>
          <div className="mt-2">
            <select
              name="district"
              required
              value={selectedDistrict}
              onChange={handleDistrictChange}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-10"
            >
              <option value="">Select a district</option>
              {districts.map((district) => (
                <option key={district.adminCode1} value={district.name}>
                  {district.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="sm:col-span-3 grid grid-cols-2 gap-x-4 mt-2">
        <div>
          <label
            htmlFor="country"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {t("registerF.personal-info.city")}
          </label>
          <div className="mt-2">
            <select
              name="city"
              value={selectedCity}
              required
              onChange={handleCityChange}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-10"
            >
              <option value="">Select a city</option>
              {cities.map((city) => (
                <option key={city.adminCode2} value={city.name}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {putParish === false ? null : (
          <div>
            <label
              htmlFor="district"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("registerF.personal-info.parish")}
            </label>
            <div className="mt-2">
              <select
                name="parish"
                value={selectedParish}
                onChange={handleParishChange}
                className="block w-full mt-2 rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-10"
              >
                <option value="">Select a parish</option>
                {parishes.map((parish) => (
                  <option key={parish.geonameId} value={parish.name}>
                    {parish.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Region;
