import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [notice, setNotice] = useState("");
  const { t } = useTranslation();
  const loginWithUsernameAndPassword = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      const tokenResult = await user.getIdTokenResult(); // Get ID token result
      const isAdmin = tokenResult.claims.role === "admin"; // Check if user has 'admin' claim

      if (isAdmin) {
        navigate("/invite");
      } else {
        navigate("/list-people");
      }

      window.location.reload(); // Optionally reload the page
    } catch {
      setNotice("You entered a wrong username or password.");
      console.log(notice)
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 mb-8">
      <Helmet>
        <title>{t("navbar.portal")}</title>
        <meta name="description" content={t("navbar.portal")} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={t("navbar.portal")} />
        <meta
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/pdm2024-2e150.appspot.com/o/general%2F174263563_padded_logo.png?alt=media&token=f85eeb16-52c7-49b8-85eb-17ab41135dd7"
        />
        <meta property="og:description" content={t("navbar.portal")} />
        <meta
          property="og:url"
          content="https://portaldasmemorias.com/lastnews"
        />
      </Helmet>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          {t("login.title")}
        </h2>
      </div>
      <div className="mt-10 mb-40 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={loginWithUsernameAndPassword}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("login.email")}
              </label>
              <div className="mt-2">
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="text-sm">
                  <Link
                    to="/forgot-password"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    {t("login.rememberpassword")}
                  </Link>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="flex w-full justify-center rounded-md bg-[#384C67]  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-48"
          >
            {t("login.button")}
          </button>
          <div className="text-center">
            <Link
              to="/allfuneralHomes"
              className="text-sm font-medium text-gray-900 hover:text-gray-700"
            >
              Funerárias Aderentes
            </Link>{" "}
              <div className="text-center">
            <Link
              to="/inquiry"
              className="text-sm font-medium text-gray-900 hover:text-gray-700"
            >
              {t("navbar.funeralHome.NewCustomer")}
            </Link>
            <div>
          </div>
        </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
