import { useTranslation } from "react-i18next";

const TermsModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`fixed z-10 inset-0 overflow-y-auto ${
        isOpen ? "block" : "hidden"
      }`}
      aria-labelledby="terms-modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          onClick={onClose}
        ></div>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left mt-10">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="terms-modal-title"
                >
                  {t("price")}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500"></p>
                  <table className="border-collapse border border-gray-300">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="p-3 border-r border-gray-300">
                          {t("service")}
                        </th>
                        <th className="p-2 border-gray-300 text-center">
                          {t("price_with_vat")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr className="border-b border-gray-300">
                        <td className="p-3 border-r border-gray-300">
                          {t("total_to_be_paid_to_funeral_home")}
                        </td>
                        <td className="p-3 border-gray-300 text-center">30€ (iva inc.)</td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="mt-10">
                    <p className="text-xs leading-5 text-gray-500">
                      {" "}
                      {t("price.notice")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={onClose}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {t("closeModal")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;
