import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const { t } = useTranslation();
  const [currentNavItem, setCurrentNavItem] = useState("person");
  //const [openFuneralMenu, setOpenFuneralMenu] = useState(false);
  // const [openFuneralOptions, setOpenFuneralOptions] = useState(false); // Novo estado para controlar a segunda dropdown

  const handleNavigation = (route) => {
    setCurrentNavItem(route);
    //setOpenFuneralMenu(false);
    //setOpenFuneralOptions(false); // Fechar a segunda dropdown ao mudar de rota
  };

  //const handleFuneralOptions = () => {
  //  setOpenFuneralOptions(!openFuneralOptions); // Inverter o estado da segunda dropdown ao clicar
  //};

  const navigation = [
    {
      name: t("navbar.home"),
      to: "/",
      onClick: () => handleNavigation("home"),
      current: currentNavItem === "home",
    },
    {
      name: t("navbar.people"),
      to: "/lastnews",
      onClick: () => handleNavigation("people"),
      current: currentNavItem === "people",
    },
    {
      name: t("navbar.aboutus"),
      to: "/aboutUs",
      onClick: () => handleNavigation("about"),
      current: currentNavItem === "about",
    },
    {
      name: t("navbar.funeralHome"),
      to: "/login",
      onClick: () => handleNavigation("funeralHome"),
      current: currentNavItem === "funeralHome",
      hasOptions: true, // Definir que este item tem uma segunda dropdown
    },
  ];
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const imageUrl =
      "https://firebasestorage.googleapis.com/v0/b/pdm2024-2e150.appspot.com/o/general%2FPdM_navbar.png?alt=media&token=1103ec6f-883e-4fbc-8fbd-e3a91a995282";
    const cachedImage = localStorage.getItem("navBarImage");

    if (cachedImage) {
      setImageSrc(cachedImage);
    } else {
      // If the image is not cached, fetch it and cache it
      setImageSrc(imageUrl);
      localStorage.setItem("navBarImage", imageUrl);
    }
  }, []);
  return (
    <Disclosure as="nav" className="bg-[#222831]">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex-shrink-0 items-center">
                <div className="flex items-center">
                  <Link to="/">
                  <img
                   src={imageSrc}
                    alt="Logo"
                    className="w-40 h-12 ml-2 sm:w-60 sm:h-12"
                  /></Link>
                </div>
              </div>
              <div className="flex items-center">
                <div className="hidden sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.to}
                        onClick={item.onClick}
                        className={classNames(
                          "rounded-md px-3 py-2 text-sm font-medium",
                          item.current
                            ? "bg-[#233957] text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => {
                if (item.to) {
                  return (
                    <Link
                      key={item.name}
                      to={item.to}
                      className={classNames(
                        item.current
                          ? "bg-[#445E81] text-white"
                          : "text-gray-300 hover:bg-[#445E81]  hover:text-white",
                        "block rounded-md px-3 py-2 text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Link>
                  );
                } else if (item.onClick) {
                  return (
                    <button
                      key={item.name}
                      onClick={item.onClick}
                      className={classNames(
                        item.current
                          ? "bg-[#445E81] text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "block rounded-md px-3 py-2 text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </button>
                  );
                }
                return null;
              })}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
