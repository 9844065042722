import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase"; // Import your firebase.js file
import { PhotoIcon } from "@heroicons/react/24/solid";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "../../Dialog/ConfirmationDialog"; // Import the ConfirmationDialog component
import TermsModal from "../../TermsModal";
import PrivacyPolicy from "../../PrivacyPolicy";
import PriceModal from "../../PriceModal";
import Region from "../../Region";

const RegistrationForm = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const e = searchParams.get("email");
  const history = useNavigate();
  const { t } = useTranslation();

  const [isValidToken, setIsValidToken] = useState(false);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [fiscalName, setFiscalName] = useState("");
  const [nif, setNif] = useState("");
  const [photo, setPhoto] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false); // State for showing confirmation dialog
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const [acceptedPrice, setAcceptedPrice] = useState(false);
  const [showPriceModal, setShowPriceModal] = useState(false);

  const [selectedRegion, setSelectedRegion] = useState({
    country: "",
    district: "",
    city: "",
  });
  const handleAcceptance = () => {
    setAcceptedTerms(!acceptedTerms);
  };

  const handleAcceptancePrice = () => {
    setAcceptedPrice(!acceptedPrice);
  };
  const handleRegionChange = (key, value) => {
    setSelectedRegion({ ...selectedRegion, [key]: value });
  };
  const handleTermsModalToggle = () => {
    setShowTermsModal(!showTermsModal);
  };
  const handlePriceModalToggle = () => {
    setShowPriceModal(!showPriceModal);
  };
  const handlePrivacyModalToggle = () => {
    setShowPolicyModal(!showPolicyModal);
  };
  useEffect(() => {
    // Perform token validation check
    const validateToken = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_URL_SERVER +
            `check-token?token=${token}&email=${email}`
        );
        if (response.ok) {
          setIsValidToken(true);
          setEmail(e);
        } else {
          setIsValidToken(false);
        }
      } catch (error) {
        console.error("Error validating token:", error);
        setIsValidToken(false);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      validateToken();
    } else {
      setIsValidToken(false);
      setLoading(false);
    }
  }, [token, e, email]);

  const handleRegister = async (e) => {
    e.preventDefault();
    setShowConfirmation(true); // Show confirmation dialog
  };

  const handleConfirmation = async () => {
    setShowConfirmation(false); // Close confirmation dialog
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const uid = userCredential.user.uid;

      const storage = getStorage();
      const imageRef = ref(storage, `funeralHomes/${uid}`);
      await uploadBytes(imageRef, photo);

      const imageUrl = await getDownloadURL(imageRef);

      const response = await fetch(
        process.env.REACT_APP_URL_SERVER + "/addFuneralHome",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            nif,
            photo: imageUrl,
            address,
            country: selectedRegion.country,
            district: selectedRegion.district,
            city: selectedRegion.city,
            uid,
            email,
            phone,
            fiscalName
          }),
        }
      );

      if (response.ok) {
        history("/lastnews");
      } else {
        throw new Error("Failed to register funeral home.");
      }
    } catch (error) {
      console.error("Error registering user:", error.message);
      setError(error.message);
    }
  };

  if (loading) {
    return <div className="text-center mt-4">{t("loading")}</div>;
  }

  if (!isValidToken) {
    return <div className="text-center mt-4">{t("expired-link")}</div>;
  }

  return (
    <div>
      <ConfirmationDialog
        open={showConfirmation}
        setOpen={setShowConfirmation}
        confirmAction={handleConfirmation}
        title={t("registerFuneralHome.createaccount.title")}
        subtile={t("registerFuneralHome.createaccount.subtile")}
        buttonText={t("registerFuneralHome.createaccount.button")}
      />
      <div className="mx-auto max-w-2xl p-6 bg-white">
        <h2 className="mt-10 text-2xl font-bold mb-4 text-center">
          {" "}
          {t("registerF.title")}
        </h2>
        <form onSubmit={handleRegister} className="">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              {t("registerF.profile")}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {t("registerF.profile.desc")}
            </p>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      required
                      readOnly
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="password"
                      name="password"
                      required
                      id="password"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2 col-span-full">
              <label
                htmlFor="cover-photo"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("registerF.logo")}
              </label>
              <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                <div className="text-center">
                  <PhotoIcon
                    className="mx-auto h-12 w-12 text-gray-300"
                    aria-hidden="true"
                  />
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span> {t("registerF.upload-photo")}</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        accept="image/*" // This attribute ensures that only image files are allowed
                        className="sr-only"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file && file.type.startsWith("image/")) {
                            // Check if the file type starts with 'image/'
                            setPhoto(file);
                          } else {
                            // Handle invalid file type
                            console.error(
                              "Invalid file type. Please select an image."
                            );
                            setError(
                              "Invalid file type. Please select an image."
                            );
                          }
                        }}
                      />

                      {photo && (
                        <img
                          src={photo}
                          alt="Selected"
                          className="w-full h-auto mb-4"
                        />
                      )}
                      {error}
                    </label>
                  </div>
                  <p className="text-xs leading-5 text-gray-600">PNG, JPG</p>
                  <p className="pl-1">
                    {photo ? photo.name : t("registerF.upload-photo.no-file")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              {t("registerF.personal-info")}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {t("registerF.personal-info.desc")}
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("registerF.personal-info.name")} {" "} Funerária
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    required
                    name="first-name"
                    id="first-name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("registerF.personal-info.name.fiscal")}
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    required
                    name="first-name"
                    id="first-name"
                    value={fiscalName}
                    onChange={(e) => setFiscalName(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("registerF.personal-info.nif")}
                </label>
                <div className="mt-2">
                  <input
                    type="number"
                    required
                    name="last-name"
                    id="last-name"
                    value={nif}
                    onChange={(e) => setNif(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("registerFuneralHome.createaccount.phone")}
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    required
                    name="phone"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="col-span-full mt-2">
                <Region
                  country={selectedRegion.country}
                  district={selectedRegion.district}
                  city={selectedRegion.city}
                  onChange={(key, value) => handleRegionChange(key, value)}
                  putParish={false}
                />
              </div>
              <div className="col-span-full">
                <label
                  htmlFor="street-address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("registerF.personal-info.address")}
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="street-address"
                    required
                    id="street-address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div className="mt-5">
              <label
                htmlFor="termsAndConditions"
                className="ml-2 block text-sm text-gray-900"
              >
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="termsAndConditions"
                    className="form-checkbox h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
                    checked={acceptedTerms}
                    onChange={handleAcceptance}
                    required
                  />
                  <span className="ml-2">
                    {t("registerFuneralHome.createaccount.terms")}{" "}
                    <button
                      onClick={handleTermsModalToggle}
                      className="text-blue-500 focus:outline-none"
                    >
                      {t("terms")}
                    </button>
                    &nbsp; {t("and")}&nbsp;
                    <button
                      onClick={handlePrivacyModalToggle}
                      className="text-blue-500 focus:outline-none"
                    >
                      {t("privacy-policy")}
                    </button>
                  </span>
                </div>
              </label>
            </div>
            <TermsModal
              isOpen={showTermsModal}
              onClose={handleTermsModalToggle}
            />
            <PrivacyPolicy
              isOpen={showPolicyModal}
              onClose={handlePrivacyModalToggle}
            />
            <div className="mt-5">
              <label
                htmlFor="termsAndConditions"
                className="ml-2 block text-sm text-gray-900"
              >
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="termsAndConditions"
                    className="form-checkbox h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
                    checked={acceptedPrice}
                    onChange={handleAcceptancePrice}
                    required
                  />
                  <span className="ml-2">
                    {t("price.accept")}{" "}
                    <button
                      onClick={handlePriceModalToggle}
                      className="text-blue-500 focus:outline-none"
                    >
                      {t("price")}
                    </button>
                  </span>
                </div>
              </label>
            </div>
            <PriceModal
              isOpen={showPriceModal}
              onClose={handlePriceModalToggle}
            />
          </div>

          {error && <div className="text-red-500 mb-4 col-span-2">{error}</div>}
          <button
            type="submit"
            className="flex w-full justify-center rounded-md bg-[#384C67]  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-20"
          >
            {t("registerF.register")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default RegistrationForm;
