import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function TributeForm(props) {
  const navigate = useNavigate(); // Use the new hook
  const { t } = useTranslation();
  const { personId, name } = props;
  const [formData, setFormData] = useState({
    relationship: "",
    author: "",
    comment: "",
    name: name,
  });
  const options = [
    t("tribute.friend"),
    t("tribute.family"),
    t("tribute.known"),
    t("tribute.unkown"),
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const offensiveWords = [
    "filho da puta",
    "arrombado",
    "puta",
    "ladrão",
    "vigarista",
    "burro",
    "palhaço",
    "vaca",
    "boi",
    "estupor",
    "estopor",
    "bandido",
    "viado",
    "paneleiro",
    "chulo",
    "xulo",
    "cabrão",
    "cona",
    "demente",
    "piça",
    "pissa",
    "ótário",
    "basculho",
    "vasculho",
    "corno",
    "vagabundo",
    "foda-se",
    "fodasse",
    "cona",
    "c0na",
    "caralho",
    "carago",
    "caluteiro",
    "caloteiro",
    "select",
    "uid",
    "id",
    "son of a bitch",
    "fucked",
    "whore",
    "thief",
    "swindler",
    "faggot",
    "faggot",
    "pimp",
    "pimp",
    "bastard",
    "cunt",
    "demented",
    "dick",
    "piss",
    "sucker",
    "trash",
    "trash",
    "cuckold",
    "bum",
    "fuck",
    "fuck",
    "cunt",
    "dick",
    "fuck",
    "swindler",
    "deadbeat",
    "hijo de puta",
    "jodido",
    "puta",
    "ladrón",
    "estafador",
    "maricón",
    "maricón",
    "chulo",
    "chulo",
    "cabrón",
    "coño",
    "demente",
    "picha",
    "meada",
    "tonto",
    "basura",
    "basura",
    "cornudo",
    "vagabundo",
    "joder",
    "joder",
    "coño",
    "polla",
    "mierda",
    "estafador",
    "moroso",
    "fdp",
    "fds",
    "crlh",
    "crl",
    "c0na"
  ];
const handleSubmit = async (e) => {
  e.preventDefault();
  
  // Verifica se o autor ou o comentário contêm palavras ofensivas
  const containsOffensiveWord = Object.entries(formData).some(([key, value]) => {
    if (key === 'author' || key === 'comment') {
      const lowerCaseValue = value.toLowerCase();
      return offensiveWords.some(word => lowerCaseValue.includes(word));
    }
    return false;
  });

  if (containsOffensiveWord) {
    alert("O seu texto contém palavras impróprias. Por favor reveja.");
    return;
  }

  try {
    await axios.post(
      `${process.env.REACT_APP_URL_SERVER}addTribute/${personId}`,
      formData
    );
    // Limpa o formulário após a submissão
    setFormData({
      relationship: "",
      author: "",
      comment: "",
      name: name,
    });
    navigate(`/tributes/${personId}`);
  } catch (error) {
    console.error("Error sending data:", error);
  }
};

  return (
    <div className="max-w-xl mx-auto mt-10 p-6 bg-white rounded-lg">
      <h2 className="text-2xl font-semibold mb-4">{t("tributes.form")} </h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            className="block text-sm font-semibold mb-1 text-left"
            htmlFor="relationship"
          >
            {t("tributes.form.rel")}
          </label>
          <select
            id="relationship"
            required
            name="relationship"
            value={formData.relationship}
            onChange={handleChange}
            className="block w-full md:w-64 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          >
            <option value="">{t("tributes.form.select-rel")}</option>
            {options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label
            className="block text-sm font-semibold mb-1 text-left"
            htmlFor="author"
          >
            {t("tributes.form.author")}
          </label>
          <input
            type="text"
            id="author"
            name="author"
            required
            value={formData.author}
            onChange={handleChange}
            className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-sm font-semibold mb-1 text-left"
            htmlFor="comment"
          >
            {t("tributes.form.comment")}
          </label>
          <textarea
            id="comment"
            name="comment"
            required
            value={formData.comment}
            onChange={handleChange}
            className="block w-full h-32 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          ></textarea>
        </div>
        <button
          type="submit"
          className="bg-[#384C67] text-white font-semibold px-4 py-2 rounded-md hover:hover:bg-gray-700 focus:outline-none focus:ring-indigo-500"
        >
          {t("tributes.form.submit")}
        </button>
      </form>
    </div>
  );
}
