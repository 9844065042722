import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function SearchBarbyCC({ currentPeople, setCurrentPeople }) {

  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    filterResults(e.target.value);
  };

  const filterResults = (term) => {
    const filteredPeople = currentPeople.filter((person) =>
      person.name.toLowerCase().includes(term.toLowerCase()) ||
      person.cc.toString().toLowerCase().includes(term.toLowerCase()) // Convert cc to string before performing toLowerCase()
      );
      //(filteredPeople)
    setCurrentPeople(filteredPeople);
  };

  return (
    <div className="relative">
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        className="input w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        placeholder= {t("searchbycc")}
      />
    </div>
  );
}

export default SearchBarbyCC;
