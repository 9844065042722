import React, { useEffect, useState } from 'react';

const ResponsiveImage = ({ src, alt, maxWidth , maxHeight}) => {
    const [dimensions, setDimensions] = useState({ width: 'auto', height: 'auto' });

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            const { naturalWidth, naturalHeight } = img;

            if (naturalWidth <= maxWidth && naturalHeight <= maxHeight) {
                // Use natural dimensions if the image is smaller than the constraints
                setDimensions({ width: `${naturalWidth}px`, height: `${naturalHeight}px` });
            } else {
                // Constrain to maxWidth and maxHeight while maintaining aspect ratio
                const aspectRatio = naturalWidth / naturalHeight;
                let newWidth = maxWidth;
                let newHeight = maxHeight;

                if (aspectRatio > 1) {
                    newHeight = maxWidth / aspectRatio;
                } else {
                    newWidth = maxHeight * aspectRatio;
                }

                setDimensions({ width: `${newWidth}px`, height: `${newHeight}px` });
            }
        };
    }, [src, maxWidth, maxHeight]);

    return (
        <img 
            src={src} 
            alt={alt} 
            className="mb-2" 
            style={{ width: dimensions.width, height: dimensions.height, maxWidth: '100%', maxHeight: '100%' }} 
        />
    );
};

export default ResponsiveImage;
