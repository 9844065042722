import { useTranslation } from "react-i18next";

const TermsModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`fixed z-10 inset-0 overflow-y-auto ${
        isOpen ? "block" : "hidden"
      }`}
      aria-labelledby="terms-modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          onClick={onClose}
        ></div>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="terms-modal-title"
                >
                  {t("terms")}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{t("termsC.welcome")}</p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.description")}
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">{t("termsC.notice")}</p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    <strong>{t("termsC.cookies.title")}</strong>
                  </p>{" "}
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.cookies.description")}
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    <strong>{t("termsC.license.title")}</strong>
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.license.description")}
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.prohibited_actions.title")}
                  </p>
                  <br></br>
                  <ul className="list-disc pl-5">
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t("termsC.prohibited_actions.actions.method1")}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t("termsC.prohibited_actions.actions.method2")}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t("termsC.prohibited_actions.actions.method3")}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t("termsC.prohibited_actions.actions.method4")}{" "}
                    </li>
                  </ul>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.agreement")}
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.comments.title")}
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.declaration.title")}
                  </p>
                  <ul className="list-disc pl-5">
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t("termsC.declaration.statements.method1")}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t("termsC.declaration.statements.method2")}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t("termsC.declaration.statements.method3")}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t("termsC.declaration.statements.method4")}{" "}
                    </li>
                  </ul>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.license_grant")}
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    <strong>{t("termsC.ownership")}</strong>
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.ownership_details")}
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    <strong>{t("termsC.obituaries.title")}</strong>
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.obituaries.announcement")}
                  </p>
                  <p className="text-sm text-gray-500">
                    {t("termsC.obituaries.removal_policy")}
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    <strong>{t("termsC.hyperlinks.title")}</strong>
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.hyperlinks.desc1")}
                  </p>
                  <br></br>
                  <ul className="list-disc pl-5">
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t(
                        "termsC.hyperlinks.allowed_organizations.method1"
                      )}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t(
                        "termsC.hyperlinks.allowed_organizations.method2"
                      )}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t(
                        "termsC.hyperlinks.allowed_organizations.method3"
                      )}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t(
                        "termsC.hyperlinks.allowed_organizations.method4"
                      )}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t(
                        "termsC.hyperlinks.allowed_organizations.method5"
                      )}{" "}
                    </li>
                  </ul>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.hyperlinks.desc2")}
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.hyperlinks.desc3")}
                  </p>
                  <br></br>
                  <ul className="list-disc pl-5">
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t("termsC.hyperlinks.other_approvals.method1")}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t("termsC.hyperlinks.other_approvals.method2")}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t("termsC.hyperlinks.other_approvals.method3")}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t("termsC.hyperlinks.other_approvals.method4")}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t("termsC.hyperlinks.other_approvals.method5")}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t("termsC.hyperlinks.other_approvals.method6")}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t("termsC.hyperlinks.other_approvals.method7")}{" "}
                    </li>
                  </ul>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.hyperlinks.desc4")}
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.hyperlinks.desc5")}
                  </p>
                  <p className="text-sm text-gray-500">
                    {t("termsC.hyperlinks.request_process")}
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.hyperlinks.approved_organizations.title")}
                  </p>{" "}
                  <br></br>
                  <ul className="list-disc pl-5">
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t(
                        "termsC.hyperlinks.approved_organizations.methods.method1"
                      )}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t(
                        "termsC.hyperlinks.approved_organizations.methods.method2"
                      )}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t(
                        "termsC.hyperlinks.approved_organizations.methods.method3"
                      )}{" "}
                    </li>
                  </ul>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.hyperlinks.approved_organizations.logo_use")}
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    <strong> {t("termsC.removal_links.title")}</strong>
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.removal_links.description")}
                  </p>
                  <p className="text-sm text-gray-500">
                    {t("termsC.removal_links.desc1")}
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    <strong> {t("termsC.accuracy_disclaimer.title")}</strong>
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.accuracy_disclaimer.details")}
                  </p>
                  <br></br>
                  {/*   <p className="text-sm text-gray-500">
                   <strong> {t("termsC.commercial_ads.title")}</strong>
                  </p>
                  <br></br>
               <p className="text-sm text-gray-500">
                    {t("termsC.commercial_ads.details")}
                  </p>    <br></br>*/}
                  <p className="text-sm text-gray-500">
                    <strong>{t("termsC.unauthorized_use.title")}</strong>
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.unauthorized_use.details")}
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    <strong> {t("termsC.terms_changes.title")}</strong>
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.terms_changes.details")}
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    <strong>{t("termsC.copyright.title")}</strong>
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.copyright.details")}
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    <strong>{t("termsC.disclaimer.title")}</strong>
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.disclaimer.details")}
                  </p>
                  <br></br>
                  <ul className="list-disc pl-5">
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t("termsC.disclaimer.methods.method1")}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t("termsC.disclaimer.methods.method2")}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t("termsC.disclaimer.methods.method3")}{" "}
                    </li>
                  </ul>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("termsC.disclaimer.desc1")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={onClose}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {t("closeModal")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;
