import React, { useState, useEffect } from "react";
import ResponsiveImage from "../ResponsiveImage";
import axios from "axios";

const Theme5 = ({
  name,
  location,
  age,
  editalText,
  editalParish,
  photo,
  emailFuneralHome,
  nameFuneralHome,
  photoFuneralHome,
  addressFuneralHome,
  selectedRegionFuneralHome,
  phone,
}) => {
  const [photoUrl, setPhotoUrl] = useState("");
  const [funeralHomeURL, setfuneralHomeURL] = useState("");
  useEffect(() => {
    const fetchPhoto = async () => {
      try {
        // Fetch person's photo using axios
        const photoResponse = await axios.get(
          `${process.env.REACT_APP_URL_SERVER}proxy`,
          {
            params: {
              url: encodeURIComponent(photo),
              callback: "handlePhoto", // Replace with your callback function name if needed
              xhr2: "false", // Change to 'true' if using XMLHttpRequest level 2 support
            },
            responseType: "text", // Response type set to handle text data
          }
        );

        if (photoResponse.status === 200) {
          const dataUriMatch = photoResponse.data.match(
            /^handlePhoto\("data:image\/jpeg;base64,([^"]+)"\);$/
          );
          if (dataUriMatch && dataUriMatch.length === 2) {
            const extractedDataUri = `data:image/jpeg;base64,${dataUriMatch[1]}`;
            // Set the extracted data URI as the photoUrl state
            setPhotoUrl(extractedDataUri);
          } else {
            throw new Error("Failed to parse photo data URI");
          }
        } else {
          throw new Error("Failed to fetch photo");
        }
      } catch (error) {
        console.error("Error fetching photo:", error);
      }
    };

    fetchPhoto();
  }, [photo]); // Trigger fetchPhoto() when 'photo' prop changes

  useEffect(() => {
    const fetchPhotoFuneralHome = async () => {
      try {
        // Fetch person's photo using axios
        const photoFuneralHomeResponse = await axios.get(
          `${process.env.REACT_APP_URL_SERVER}proxy`,
          {
            params: {
              url: encodeURIComponent(photoFuneralHome),
              callback: "handlePhoto", // Replace with your callback function name if needed
              xhr2: "false", // Change to 'true' if using XMLHttpRequest level 2 support
            },
            responseType: "text", // Response type set to handle text data
          }
        );

        if (photoFuneralHomeResponse.status === 200) {
          const dataUriMatch2 = photoFuneralHomeResponse.data.match(
            /^handlePhoto\("data:image\/jpeg;base64,([^"]+)"\);$/
          );
          if (dataUriMatch2 && dataUriMatch2.length === 2) {
            const extractedDataUri2 = `data:image/jpeg;base64,${dataUriMatch2[1]}`;

            // Set the extracted data URI as the photoUrl state
            setfuneralHomeURL(extractedDataUri2);
          } else {
            throw new Error("Failed to parse photo data URI");
          }
        } else {
          throw new Error("Failed to fetch photo");
        }
      } catch (error) {
        console.error("Error fetching photo:", error);
      }
    };

    fetchPhotoFuneralHome();
  }, [photoFuneralHome]); // Trigger fetchPhoto() when 'photo' prop changes

  return (
    <div
      className="relative w-[210mm] h-[297mm] bg-cover border-2 border-gray-300"
      style={{ backgroundImage: "url('/5.png')" }}
    >
      <div className="absolute inset-0 p-20 flex flex-col justify-between">
        <div>
          <img
            src={photoUrl}
            alt="person"
            className="w-60 h-60 mb-5 mx-auto block mt-10"
          />
          <h1 className="text-center text-3xl font-bold mb-2">{name}</h1>
          <div
            className="text-center text-2xl mb-1"
            dangerouslySetInnerHTML={{ __html: editalParish }}
          />
          <h2 className="text-center text-xl">({age} anos)</h2>
          <div
            className="text-justify text-2xl ml-5 mt-10 w-[580px]"
            dangerouslySetInnerHTML={{ __html: editalText }}
          />
        </div>
        <div className="absolute bottom-20 left-[20em] ">
          <ResponsiveImage
            src={funeralHomeURL}
            alt="logo"
            maxWidth="160"
            maxHeight="160"
          />
        </div>
      </div>
    </div>
  );
};
export default Theme5;
