import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import ConfirmationDialog from "../../Dialog/ConfirmationDialog";
import Region from "../../Region";
const EditPerson = () => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [photo, setPhoto] = useState("");
  const [cc, setCC] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false); // State for showing confirmation dialog
  const [funeralDetails, setFuneralDetails] = useState({
    celebration: "",
    church: {
      address: "",
      time: "",
      day: "",
    },
    funeral: {
      address: "",
      time: "",
      day: "",
    },
    funeral_services: "",
    additional_info: "",
  });
  const [birthday, setBirthday] = useState("");
  const [death, setDeath] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState({
    country: "",
    district: "",
    city: "",
    parish: "",
  });
  const handleRegionChange = (key, value) => {
    setSelectedRegion({ ...selectedRegion, [key]: value });
  };
  const [loading, setLoading] = useState(true); // Loading state

  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.stsTokenManager.accessToken;
  useEffect(() => {
    const fetchPerson = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL_SERVER}/getPersonbyIdFuneralHome/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setName(data.name);
          setSelectedRegion({
            country: data.country,
            district: data.district,
            city: data.city,
            parish: data.parish,
          });
          setCC(data.cc);
          setBirthday(data.birthday);
          setDeath(data.death);
          setDescription(data.description);
          setFuneralDetails(data.funeralDetails);
          setPhoto(data.photo);
        } else {
          throw new Error("Failed to fetch person");
        }
      } catch (error) {
        console.error("Error fetching person:", error);
        setError(error.message);
      } finally {
        setLoading(false); // Set loading to false after fetching data
      }
    };

    fetchPerson();
  }, [id, token]);
  const handleRegister = async (e) => {
    e.preventDefault();
    setShowConfirmation(true); // Show confirmation dialog
  };
  const handleSubmit = async (e) => {
    setShowConfirmation(false); // Close confirmation dialog

    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const token = user.stsTokenManager.accessToken;

      const response = await fetch(
        `${process.env.REACT_APP_URL_SERVER}updatePerson/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id,
            name,
            photo: photo,
            country: selectedRegion.country,
            district: selectedRegion.district,
            city: selectedRegion.city,
            parish: selectedRegion.parish,
            funeralDetails,
            birthday,
            death,
            description,
          }),
        }
      );

      if (response.ok) {
        // Redirect the user to the desired page after successful update
        navigate(`/person/${id}`);
      } else {
        throw new Error("Failed to update person.");
      }
    } catch (error) {
      console.error("Error updating person:", error.message);
      setError(error.message);
    }
  };
  if (loading) {
    return <div>{t("loading")}</div>;
  }
  return (
    <div className="mx-auto max-w-2xl p-6 bg-white">
      <ConfirmationDialog
        open={showConfirmation}
        setOpen={setShowConfirmation}
        confirmAction={handleSubmit}
        title="Alterar Dados Biográficos"
        subtile="Confirma que todos os dados inseridos são reais?"
        buttonText="Alterar"
      />
      <h2 className="mt-10 text-2xl font-bold mb-4 text-center">
        {t("editcelebration.edit.title")}
      </h2>
      <form onSubmit={handleRegister} className="">
        <div className="border-b border-gray-900/10 pb-12">
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-full">
              <label
                htmlFor="username"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("registerF.personal-info.name")}
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    readOnly
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                htmlFor="username"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("register.personal-info.cc")}
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="number"
                    name="cc"
                    id="cc"
                    readOnly
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    value={cc}
                    onChange={(e) => setCC(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            {t("registerF.personal-info")}
          </h2>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3 sm:col-start-1">
              <label
                htmlFor="city"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("register.personal-info.birth")}
              </label>
              <div className="mt-2">
                <input
                  type="date"
                  name="birthday"
                  id="birthday"
                  required
                  value={birthday}
                  onChange={(e) => setBirthday(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="region"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("register.personal-info.death")}
              </label>
              <div className="mt-2">
                <input
                  type="date"
                  name="death"
                  value={death}
                  required
                  onChange={(e) => setDeath(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-full">
              <label
                htmlFor="street-address"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("register.personal-info.description")}
              </label>
              <div className="mt-2">
                <textarea
                  rows="4"
                  cols="50"
                  name="description"
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className="col-span-full">
            <Region
              country={selectedRegion.country}
              district={selectedRegion.district}
              city={selectedRegion.city}
              parish={selectedRegion.parish}
              onChange={(key, value) => handleRegionChange(key, value)}
              putParish={true}
            />
          </div>
        </div>
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            {t("register.personal-info.funeral.title")}{" "}
          </h2>{" "}
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="church-address"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("register.personal-info.funeralday")}
              </label>
              <div className="mt-2">
                <input
                  type="date"
                  name="funeral-day"
                  id="funeral-day"
                  required
                  value={funeralDetails.funeral.day}
                  onChange={(e) =>
                    setFuneralDetails({
                      ...funeralDetails,
                      funeral: {
                        ...funeralDetails.funeral,
                        day: e.target.value,
                      },
                    })
                  }
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            {/* <div className="sm:col-span-3">
              <label
                htmlFor="church-time"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("register.personal-info.funeralhour")}
              </label>
              <div className="mt-2">
                <input
                  type="time"
                  name="funeral-time"
                  id="funeral-time"
                  required
                  value={funeralDetails.funeral.time}
                  onChange={(e) =>
                    setFuneralDetails({
                      ...funeralDetails,
                      funeral: {
                        ...funeralDetails.funeral,
                        time: e.target.value,
                      },
                    })
                  }
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div> */}
            <div className="sm:col-span-full">
              <label
                htmlFor="church-day"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("register.personal-info.funeraladdress")}{" "}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="church-day"
                  id="church-day"
                  required
                  value={funeralDetails.funeral.address}
                  onChange={(e) =>
                    setFuneralDetails({
                      ...funeralDetails,
                      funeral: {
                        ...funeralDetails.funeral,
                        address: e.target.value,
                      },
                    })
                  }
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>
        {error && <div className="text-red-500 mb-4 col-span-2">{error}</div>}
        <button
          type="submit"
          className="flex w-full justify-center rounded-md bg-[#384C67]  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-20"
        >
          {t("editcelebration.edit.title")}
        </button>
      </form>
    </div>
  );
};

export default EditPerson;
