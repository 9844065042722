import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: require('./locales/en.json'),
  },
  pt: {
    translation: require('./locales/pt.json'),
  },
  es: {
    translation: require('./locales/es.json'),
  }
};

const fallbackLanguage = 'pt';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: fallbackLanguage,
    detection: {
      order: ['navigator'],
    },
    interpolation: {
      escapeValue: false,
    },
    lng: navigator.language || fallbackLanguage,
  });

export default i18n;
