// src/components/AccessDenied.js

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const AccessDenied = () => {
    const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-800">{t("access.denied")}</h1>
        <p className="mt-2 text-lg text-gray-600">
          {t("access.denied.text")}
        </p>
        <Link to="/lastnews" className="mt-4 text-blue-500 hover:underline">
          {t("access.denied.back")}
        </Link>
      </div>
    </div>
  );
};

export default AccessDenied;
