import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { auth } from "./firebase"; // Import your firebase.js file
import Navbar from "./Navbar/Navbar";
import AdminNavbar from "./Navbar/AdminNavbar"; // Import the new AdminNavbar component
import LoginNavBar from "./Navbar/LoginNavbar";
import Home from "./Home";
import LastNews from "./LastNews/LastNews";
import Person from "./Person/Person";
import Tributes from "./Person/Tributes";
import AccessDenied from "./FalloutPages/AccessDenied";
import NotFound from "./FalloutPages/NotFound";
import PleaseLogout from "./FalloutPages/PleaseLogout";
import RegistrationForm from "./FuneralHome/Account/RegistrationForm";
import EditProfile from "./FuneralHome/Account/EditProfile";
import Login from "./Authentication/Login";
import Footer from "./footer";
import ListPeoplebyFuneral from "./FuneralHome/Actions/ListPeoplebyFuneral";
import AddPerson from "./FuneralHome/Actions/AddNewPerson";
import EditPerson from "./FuneralHome/Actions/EditPersonProfile";
import EditPersonCelebration from "./FuneralHome/Actions/EditPersonCelebration";
import RecoveryPassword from "./Authentication/RecoveryPassword";
import Billing from "./Billing/Billing";
import InviteFuneralHomes from "./AdminDashboard/InviteFuneralHomes";
import AdminBilling from "./AdminDashboard/AdminBilling";
import InquiryPage from "./FuneralHome/Actions/InquiryPage";
import { HelmetProvider } from "react-helmet-async";
import About from "./About";
import ListAllFuneral from "./FuneralHome/Account/ListAllFuneralHome";
import ConsentBanner from "./ConsentBanner/banner";
import { useCookies } from "react-cookie";
import GenerateEdital from "./FuneralHome/Actions/CreateEdital/GenerateEdital";
import GeneratePagela from "./FuneralHome/Actions/CreatePagela/GeneratePagela";

const helmetContext = {}; // Create a context

function App() {
  const [user, setUser] = useState(null); // Initialize user state as null
  const [isAdmin, setIsAdmin] = useState(false); // Initialize isAdmin state as false

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setUser(storedUser);
    }
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        setUser(authUser);
        localStorage.setItem("user", JSON.stringify(authUser));
        const tokenResult = await authUser.getIdTokenResult(); // Get ID token result
        console.log(tokenResult.claims.role); // Log the user's role for debugging purposes
        setIsAdmin(tokenResult.claims.role === "admin"); // Check if user has 'admin' role claim
      } else {
        setUser(null);
        setIsAdmin(false);
        localStorage.removeItem("user");
      }
    });

    return () => unsubscribe();
  }, []);
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register("/service-worker.js")
          .then((registration) => {
            console.log(
              "Service worker registration successful:",
              registration
            );
          })
          .catch((error) => {
            console.error("Service worker registration failed:", error);
          });
      });
    }
  }, []);
  const isLoggedIn = !!user;
  const [cookies] = useCookies(["cookieConsent"]);

  return (
    <div className="App">
      <HelmetProvider context={helmetContext}>
        {isLoggedIn ? isAdmin ? <AdminNavbar /> : <LoginNavBar /> : <Navbar />}

        <Routes>
          <Route path="*" element={<NotFound />} />
          {/* No permission needed */}
          <Route path="/lastnews" element={<LastNews />} />
          <Route path="/person/:id" element={<Person />} />
          <Route path="/tributes/:id" element={<Tributes />} />
          <Route path="/forgot-password" element={<RecoveryPassword />} />
          <Route path="/aboutUs" element={<About />} />
          {/* Logout needed */}
          <Route path="/" element={<Home />} />
          <Route
            path="/inquiry"
            element={!isLoggedIn ? <InquiryPage /> : <PleaseLogout />}
          />
          <Route
            path="/allfuneralHomes"
            element={!isLoggedIn ? <ListAllFuneral /> : <PleaseLogout />}
          />
          <Route
            path="/login"
            element={!isLoggedIn ? <Login /> : <PleaseLogout />}
          />
          <Route
            path="/register"
            element={!isLoggedIn ? <RegistrationForm /> : <AccessDenied />}
          />

          {/* Permission needed */}
          <Route
            path="/list-people/"
            element={isLoggedIn ? <ListPeoplebyFuneral /> : <AccessDenied />}
          />
          <Route
            path="/add-people/"
            element={isLoggedIn ? <AddPerson /> : <AccessDenied />}
          />
          <Route
            path="/edit-people/:id"
            element={isLoggedIn ? <EditPerson /> : <AccessDenied />}
          />
          <Route
            path="/edit-people-celebration/:id"
            element={isLoggedIn ? <EditPersonCelebration /> : <AccessDenied />}
          />
          <Route
            path="/generate-edital/:id"
            element={isLoggedIn ? <GenerateEdital /> : <AccessDenied />}
          />
           <Route
            path="/generate-pagela/:id"
            element={isLoggedIn ? <GeneratePagela /> : <AccessDenied />}
          />
          <Route
            path="/profile"
            element={isLoggedIn ? <EditProfile /> : <AccessDenied />}
          />
          <Route
            path="/billing"
            element={isLoggedIn ? <Billing /> : <AccessDenied />}
          />

          {/* admin dashboard routes */}
          <Route
            path="/invite"
            element={
              isLoggedIn && isAdmin ? <InviteFuneralHomes /> : <AccessDenied />
            }
          />
          <Route
            path="/admin/billing"
            element={
              isLoggedIn && isAdmin ? <AdminBilling /> : <AccessDenied />
            }
          />
        </Routes>
             {!cookies.cookieConsent && <ConsentBanner />}
        <Footer></Footer>
           
      </HelmetProvider>
    </div>
  );
}

export default App;
