import { useTranslation } from "react-i18next";

const PrivacyPolacy = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`fixed z-10 inset-0 overflow-y-auto ${
        isOpen ? "block" : "hidden"
      }`}
      aria-labelledby="privacy-modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          onClick={onClose}
        ></div>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="privacy-modal-title"
                >
                  {t("privacypolicycontent.title")}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {t("privacypolicycontent.ownership")}
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("privacypolicycontent.introduction")}
                  </p>{" "}
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("privacypolicycontent.data_protection")}
                  </p>{" "}
                  <br></br>
                  <p className="text-sm text-gray-500">
                    <strong>
                      {" "}
                      {t("privacypolicycontent.personal_info_collected.title")}
                    </strong>
                  </p>{" "}
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t(
                      "privacypolicycontent.personal_info_collected.content.method1"
                    )}
                  </p>
                  <p className="text-sm text-gray-500">
                    {t(
                      "privacypolicycontent.personal_info_collected.content.method2"
                    )}
                  </p>
                  <p className="text-sm text-gray-500">
                    {t(
                      "privacypolicycontent.personal_info_collected.content.method3"
                    )}
                  </p>{" "}
                  <br></br>
                  <p className="text-sm text-gray-500">
                    <strong>
                      {" "}
                      {t("privacypolicycontent.data_processing.title")}
                    </strong>
                  </p>{" "}
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("privacypolicycontent.data_processing.content.method1")}
                  </p>
                  <p className="text-sm text-gray-500">
                    {t("privacypolicycontent.data_processing.content.method2")}
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("privacypolicycontent.data_processing.desc1")}
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    <strong>
                      {" "}
                      {t("privacypolicycontent.user_rights.title")}
                    </strong>
                  </p>{" "}
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("privacypolicycontent.user_rights.desc1")}
                  </p>
                  <br></br>
                  <ul className="list-disc pl-5">
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t(
                        "privacypolicycontent.user_rights.content.method1"
                      )}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t(
                        "privacypolicycontent.user_rights.content.method2"
                      )}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t(
                        "privacypolicycontent.user_rights.content.method3"
                      )}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t(
                        "privacypolicycontent.user_rights.content.method4"
                      )}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t(
                        "privacypolicycontent.user_rights.content.method5"
                      )}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t(
                        "privacypolicycontent.user_rights.content.method6"
                      )}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t(
                        "privacypolicycontent.user_rights.content.method7"
                      )}{" "}
                    </li>
                    <li className="text-sm text-gray-500">
                      {" "}
                      {t(
                        "privacypolicycontent.user_rights.content.method8"
                      )}{" "}
                    </li>
                  </ul>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("privacypolicycontent.user_rights.desc2")}
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    <strong>
                      {" "}
                      {t("privacypolicycontent.external_links.title")}
                    </strong>
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-500">
                    {t("privacypolicycontent.external_links.content.method1")}
                  </p><br></br>
                  <p className="text-sm text-gray-500">
                    <strong>
                      {" "}
                      {t("privacypolicycontent.information_security.title")}
                    </strong>
                  </p> <br></br>
                  <p className="text-sm text-gray-500">
                    {t(
                      "privacypolicycontent.information_security.content.method1"
                    )}
                  </p> <br></br>
                  <p className="text-sm text-gray-500">
                    <strong>
                      {" "}
                      {t("privacypolicycontent.legal_disclosure.title")}
                    </strong>
                  </p> <br></br>
                  <p className="text-sm text-gray-500">
                    {t("privacypolicycontent.legal_disclosure.content.method1")}
                  </p><br></br>
                  <p className="text-sm text-gray-500">
                    <strong>
                      {" "}
                      {t("privacypolicycontent.contact_information.title")}
                    </strong>
                  </p> <br></br>
                  <p className="text-sm text-gray-500">
                    {t(
                      "privacypolicycontent.contact_information.content.method1"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={onClose}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {t('closeModal')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolacy;
