/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function Home() {
  const { t } = useTranslation();
  const [feature, setFeatured] = useState([]);
  const [lastTributes, setLastTributes] = useState([]);
  const [email, setEmail] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_SERVER}inquiry`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, name: "", phone: "" }),
        }
      );

      if (response.ok) {
        window.location.reload();
      } else {
        console.error("Failed to send inquiry:", response.statusText);
      }
    } catch (error) {
      console.error("Error sending inquiry:", error);
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const fetchData = async (url) => {
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  const fetchDataWithCache = async (url, cacheKey, setStateFunction) => {
    try {
      const cachedData = localStorage.getItem(cacheKey);
      if (cachedData) {
        setStateFunction(JSON.parse(cachedData));
      }

      const data = await fetchData(url); // Use fetchData function to fetch data
      if (data) {
        localStorage.setItem(cacheKey, JSON.stringify(data));
        setStateFunction(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDataWithCache(
      process.env.REACT_APP_URL_SERVER + "getFeaturePeople",
      "FeaturePersons",
      setFeatured
    );
  }, []);

  useEffect(() => {
    fetchDataWithCache(
      process.env.REACT_APP_URL_SERVER + "getLastTributes",
      "Tributes",
      setLastTributes
    );
  }, []);
  const memoizedFeature = useMemo(() => feature, [feature]);
  const memoizedLastTributes = useMemo(() => lastTributes, [lastTributes]);

  return (
    <div className="bg-white py-32 sm:py-32">
      {/* Em destaque section */}
      <Helmet>
        <title>Portal das Memórias</title>
        <meta name="description" content={t("description")} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Portal das Memórias" />
        <meta property="og:site_name" content="Portal das Memórias" />
        <meta
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/pdm2024-2e150.appspot.com/o/general%2F174263563_padded_logo.png?alt=media&token=f85eeb16-52c7-49b8-85eb-17ab41135dd7"
        />
        <meta
          property="og:description"
          content="Com o Portal das Memórias, é possível descobrir e recordar as pessoas que moldaram as nossas vidas. Neste espaço, é possível deixar tributos aos que nos deixaram, honrando suas memórias de forma significativa e duradoura, ou partilhar histórias que celebrem suas vidas e legados únicos"
        />
        <meta property="og:site_name" content="Portal das Memórias" />
      </Helmet>

      <div className="mx-auto max-w-2xl lg:text-center mb-5">
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {t("home.welcome")}
        </p>
        <p class="mt-6 text-lg leading-8 text-gray-600">{t("home.subtitle")}</p>
      </div>

      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mt-1 grid grid-cols-1 gap-y-10 sm:gap-y-20 lg:grid-cols-2 xl:grid-cols-3">
          {memoizedFeature.length === 0 ? (
            <p>{t("home.nofeature")}</p>
          ) : (
            memoizedFeature.map((person) => {
              const deathYear = person.death.split("-")[0];
              const birthYear = person.birthday.split("-")[0];

              return (
                <Link to={`/person/${person.id}`} key={person.id}>
                  <div className="bg-gray-195  p-4 rounded-lg shadow-md mr-2 border">
                    <p className="text-sm text-gray-500">
                      {person.funeralDetails.celebration}
                    </p>
                    <div className="group relative w-full">
                      <div className="relative h-64 sm:h-48 lg:h-64 rounded-md overflow-hidden mb-4">
                        <img
                          src={person.photo}
                          alt={person.name}
                          className="object-cover w-full h-full"
                        />
                      </div>
                      <div className="text-center">
                        <h2 className="text-lg font-bold text-gray-800 mb-2">
                          {person.name}
                        </h2>
                        <p className="text-sm text-gray-500 mb-1">
                          {person.city.replace(" Municipality", "")}
                        </p>
                        <p className="text-sm text-gray-500 mb-1">
                          {birthYear} - {deathYear}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })
          )}
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <div className="flex flex-col items-center">
              <Link
                to={`/lastnews`}
                className="mt-5 flex items-center justify-center rounded-md border border-transparent bg-[#384C67] px-6 py-2 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                {t("home.seeall")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div class="border-t border-gray-300 my-8"></div>
      {/* Last Community Tributes*/}
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <p className=" text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {t("home.tribute")}
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              {t("home.check")}
            </p>
          </div>
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16  pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {memoizedLastTributes.map((tribute) => (
              <article
                key={tribute.id}
                className="flex flex-col max-w-xl items-start justify-between bg-white p-6 rounded-lg shadow-md"
              >
                <div className="flex items-center text-xs gap-x-4">
                  <time dateTime={tribute.datetime} className="text-gray-500">
                    {tribute.date}
                  </time>
                  <p className="relative z-10 bg-[#222831] px-3 py-1.5 rounded-full font-medium text-[#ffffff]">
                    {tribute.relationship}
                  </p>
                </div>
                <div className="flex flex-col text-xs gap-y-2">
                  <div className="flex items-start">
                    <h3 className="text-lg font-semibold leading-6 text-gray-900">
                      {tribute.name}
                    </h3>
                  </div>
                  <div className="group relative">
                    <p className="line-clamp-3 text-sm leading-5 text-gray-600">
                      {tribute.comment}
                    </p>
                  </div>
                </div>

                <div className="relative mt-8 flex items-center gap-x-4">
                  <div className="text-sm leading-6">
                    <p className="font-semibold text-gray-900">
                      <p>
                        <span className="absolute inset-0" />
                        {tribute.author}
                      </p>
                    </p>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
      {/* Are you a funeral Home section */}
      <div className=" overflow-hidden bg-[#788FAD] py-16 sm:py-24 lg:py-32">
        <div class="flex justify-center">
          <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="max-w-xl lg:max-w-lg">
              <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                {t("home.areyoufuneral")}
              </h2>
              <p class="mt-4 text-lg leading-8 text-gray-300">
                {t("home.contactus")}
              </p>
              <form
                onSubmit={handleSubmit}
                className="mt-6 flex max-w-md gap-x-4"
              >
                <label htmlFor="email-address" className="sr-only">
                  {t("home.contactus.email")}
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={handleEmailChange}
                  className="min-w-0 flex-auto rounded-md border-0 bg-white px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  placeholder={t("home.contactus.email.placeholder")}
                />
                <button
                  type="submit"
                  className="flex-none rounded-md bg-[#384C67] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                >
                  {t("home.inquiry")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
