/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import SearchBarbyCC from "./SearchbyCC";
import { useTranslation } from "react-i18next";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function ListPeoplebyFuneral() {
  const [people, setPeople] = useState([]);
  const [currentPeople, setCurrentPeople] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const uid = user.uid;
  const token = user.stsTokenManager.accessToken;
  const { t } = useTranslation();
  useEffect(() => {
    const fetchPeople = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL_SERVER}/getPortofolio/${uid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const allPeople = Object.values(response.data).flat();
        setPeople(allPeople);
        setCurrentPeople(allPeople); // Initialize currentPeople with all people
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPeople();
  }, [token, uid]);

  const formatDate = (dateString) => {
    return format(new Date(dateString), "dd/MM/yyyy HH:mm:ss");
  };
  const handleSearch = (filteredPeople) => {
    setCurrentPeople(filteredPeople);
  };
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [personToDelete, setPersontoDelete] = useState();
  const openModal = (person) => {
    setIsModalOpen(true);
    setPersontoDelete(person);
  };

  const handleDelete = async () => {
    console.log("aqui");

    const response = await fetch(
      `${process.env.REACT_APP_URL_SERVER}deletePerson/${personToDelete.id}/${uid}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.ok) {
      // Handle successful deletion (e.g., refresh data or navigate away)
      console.log("Person deleted successfully");
      window.location.reload();
      setIsModalOpen(false);
    } else {
      // Handle error
      console.error("Failed to delete person");
    }
  };
  return (
    <div className="bg-white py-10 sm:py-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <div className="space-y-12">
            <SearchBarbyCC
              currentPeople={people}
              setCurrentPeople={handleSearch}
            />
            <div className="border-b border-gray-900/10 pb-12">
              <div className="mt-10 ">
                <div className=""></div>
                <ul role="list" className="divide-y divide-gray-100">
                  {Array.isArray(currentPeople) && currentPeople.length > 0 ? (
                    currentPeople.map((person) => (
                      <li
                        key={person.id}
                        className="flex flex-col sm:flex-row justify-between gap-x-6 py-5"
                      >
                        <div className="flex min-w-0 gap-x-4 mb-2 sm:mb-0">
                          <img
                            className="h-12 w-12 flex-none rounded-full bg-gray-50"
                            src={person.photo}
                            alt=""
                          />
                          <div className="min-w-0 flex-auto">
                            <p className="text-sm font-semibold leading-6 text-gray-900">
                              {person.name}
                            </p>
                            <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                              {person.cc}
                            </p>
                          </div>
                        </div>
                        {!person.name.includes("**removido**") ? (
                          <div className="block sm:flex flex-col items-end">
                            <Link
                              to={`/person/${person.id}`}
                              className=" inline-flex text-sm leading-6 text-gray-900 mb-1 sm:mb-0"
                            >
                              {t("list-people.seeobit")}
                            </Link>

                            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                              <Menu
                                as="div"
                                className="relative inline-block text-left"
                              >
                                <div>
                                  <Menu.Button className="inline-flex text-sm leading-6 text-gray-900 mb-1 sm:mb-0">
                                    Editar Informações
                                    <ChevronDownIcon
                                      className="-mr-1 h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                      <Menu.Item>
                                        {({ focus }) => (
                                          <Link
                                            to={`/edit-people-celebration/${person.id}`}
                                            className={classNames(
                                              focus
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block px-4 py-2 text-sm"
                                            )}
                                          >
                                            {t("list-people.updateCerimony")}
                                          </Link>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ focus }) => (
                                          <Link
                                            to={`/edit-people/${person.id}`}
                                            className={classNames(
                                              focus
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block px-4 py-2 text-sm"
                                            )}
                                          >
                                            {t("list-people.updateProfile")}
                                          </Link>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ focus }) => (
                                          <button
                                            onClick={() => openModal(person)}
                                            className={classNames(
                                              focus
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block px-4 py-2 text-sm w-full text-left"
                                            )}
                                          >
                                            Apagar Pessoa
                                          </button>
                                        )}
                                      </Menu.Item>
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </div>
                            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                              <Menu
                                as="div"
                                className="relative inline-block text-left"
                              >
                                <div>
                                  <Menu.Button className="inline-flex text-sm leading-6 text-gray-900 mb-1 sm:mb-0">
                                    Criação de:
                                    <ChevronDownIcon
                                      className="-mr-1 h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                      <Menu.Item>
                                        {({ focus }) => (
                                          <Link
                                            to={`/generate-edital/${person.id}`}
                                            className={classNames(
                                              focus
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block px-4 py-2 text-sm"
                                            )}
                                          >
                                            Editais
                                          </Link>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ focus }) => (
                                          <Link
                                            to={`/generate-pagela/${person.id}`}
                                            className={classNames(
                                              focus
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block px-4 py-2 text-sm"
                                            )}
                                          >
                                            Pagelas
                                          </Link>
                                        )}
                                      </Menu.Item>
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </div>

                            <p className="text-xs leading-5 text-gray-500">
                              {t("list-people.added_at")}{" "}
                              {formatDate(person.created_at)}
                            </p>
                          </div>
                        ) : (
                          <p>Esta pessoa foi removida</p>
                        )}
                        {isModalOpen && (
                          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                            <div className="bg-white p-6 rounded-lg shadow-lg">
                              <h2 className="text-xl font-semibold">
                                Apagar Pessoa 
                              </h2>
                              <p>Tem certeza que quer apagar: {personToDelete.name}?</p>
                              <div className="mt-4 flex justify-end">
                                <button
                                  className="mr-2 p-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                                  onClick={() => setIsModalOpen(false)}
                                >
                                  Cancelar
                                </button>
                                <button
                                  className="p-2 bg-red-500 text-white rounded hover:bg-red-600"
                                  onClick={() => handleDelete(person)}
                                >
                                  Apagar
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </li>
                    ))
                  ) : (
                    <li>{t("list-people.nopeople")}</li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
