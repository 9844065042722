import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey:  process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId:  process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket:  process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGESENDERID,
  appId:  process.env.REACT_APP_FIREBASE_APPID,
  measurementId:  process.env.REACT_APP_FIREBASE_MEASUREID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth }