/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";

const AdminBillingPage = () => {
  const [billingData, setBillingData] = useState(null);
  const [selectedFuneralHome, setSelectedFuneralHome] = useState("");
  const [fiscalName, setFiscalName] = useState("");
  const [address, setAddress] = useState("");
  const [nif, setNif] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [funeralHomeOptions, setFuneralHomeOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [monthOptions, setMonthOptions] = useState([]);
  const [billingResult, setBillingResult] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.stsTokenManager.accessToken;

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (billingData) {
      setFuneralHomeOptions(
        Object.keys(billingData).map((funeralHomeId) => ({
          value: funeralHomeId,
          label: billingData[funeralHomeId].name,
        }))
      );
    }
  }, [billingData]);

  useEffect(() => {
    if (selectedFuneralHome && billingData) {
      setYearOptions(
        Object.keys(billingData[selectedFuneralHome]?.billingByYear || {}).map(
          (year) => ({
            value: year,
            label: year,
          })
        )
      );
      setSelectedYear("");
      setSelectedMonth("");
    }
  }, [selectedFuneralHome, billingData]);

  useEffect(() => {
    if (selectedYear && billingData) {
      setMonthOptions(
        Object.keys(
          billingData[selectedFuneralHome]?.billingByYear?.[selectedYear] || {}
        ).map((month) => ({
          value: month,
          label: month,
        }))
      );
      setSelectedMonth("");
    }
  }, [selectedYear, billingData, selectedFuneralHome]);

  useEffect(() => {
    fetchBillingData(selectedFuneralHome, selectedYear, selectedMonth);
  }, [selectedMonth]);

  const fetchData = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_URL_SERVER}admin/checkbilling`,
        { headers }
      );
      setBillingData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchBillingData = (funeralHome, year, month) => {
    if (funeralHome && year && month && billingData) {
      const selectedBilling =
        billingData[funeralHome]?.billingByYear?.[year]?.[month] || {};
      setBillingResult(selectedBilling);
    } else {
      setBillingResult(null);
    }
  };
  useEffect(() => {
    const fetchFuneralHome = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL_SERVER}getFuneralHomeByIdforProfile/${selectedFuneralHome}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setFiscalName(data.fiscalName);
          setAddress(data.address);
          setNif(data.nif);
          setEmail(data.email);
          setPhone(data.phone);
          setSelectedRegion({
            country: data.country,
            district: data.district,
            city: data.city,
          });
        } else {
          throw new Error("Failed to fetch funeral home data.");
        }
      } catch (error) {
        console.error("Error fetching funeral home data:", error);
      }
    };

    fetchFuneralHome();
  }, [selectedFuneralHome, token]);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-xl mx-auto">
        <h2 className="text-2xl font-bold mb-4">Página de Faturamento</h2>
        <div className="mb-4">
          <label htmlFor="funeralHome" className="block font-medium mb-2">
            Select Funeral Home:
          </label>
          <select
            id="funeralHome"
            className="block w-full p-2 border border-gray-300 rounded-md"
            value={selectedFuneralHome}
            onChange={(e) => setSelectedFuneralHome(e.target.value)}
          >
            <option value="">Select a Funeral Home</option>
            {funeralHomeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        {selectedFuneralHome && (
          <div className="mb-4">
            <label htmlFor="year" className="block font-medium mb-2">
              Select Year:
            </label>
            <select
              id="year"
              className="block w-full p-2 border border-gray-300 rounded-md"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
            >
              <option value="">Select a Year</option>
              {yearOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        )}
        {selectedYear && (
          <div className="mb-4">
            <label htmlFor="month" className="block font-medium mb-2">
              Select Month:
            </label>
            <select
              id="month"
              className="block w-full p-2 border border-gray-300 rounded-md"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
            >
              <option value="">Select a Month</option>
              {monthOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      {billingResult && (
        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">Dados Faturamento: </h2>

          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 p-3">Nome Fiscal</th>
                <th className="border border-gray-300 p-3">NIF/ NIPC</th>
                <th className="border border-gray-300 p-3">Morada</th>
                <th className="border border-gray-300 p-3">País</th>
                <th className="border border-gray-300 p-3">Distrito</th>
                <th className="border border-gray-300 p-3">Cidade</th>
                <th className="border border-gray-300 p-3">Email</th>
                <th className="border border-gray-300 p-3">Telemóvel</th>
              </tr>
            </thead>
            <tbody>
              <tr className="hover:bg-gray-50">
                <td className="border border-gray-300 p-3">{fiscalName}</td>
                <td className="border border-gray-300 p-3">{nif}</td>
                <td className="border border-gray-300 p-3">{address}</td>
                <td className="border border-gray-300 p-3">
                  {selectedRegion.country}
                </td>
                <td className="border border-gray-300 p-3">
                  {selectedRegion.district}
                </td>
                <td className="border border-gray-300 p-3">
                  {selectedRegion.city}
                </td>
                <td className="border border-gray-300 p-3">{email}</td>
                <td className="border border-gray-300 p-3">{phone}</td>
              </tr>
            </tbody>
          </table>
          <h2 className="text-xl font-bold mb-4">
            Resultado - {billingResult.totalBilling.toFixed(2)} euros{" "}
          </h2>
          <div className="overflow-x-auto">
            <table className="w-full border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border border-gray-300 p-3">Pessoa</th>
                  <th className="border border-gray-300 p-3">CC</th>
                  <th className="border border-gray-300 p-3">Adiciona a</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(billingResult.persons).map(
                  ([personId, person]) => (
                    <tr key={personId} className="hover:bg-gray-50">
                      <td className="border border-gray-300 p-3">
                        {person.name}
                      </td>
                      <td className="border border-gray-300 p-3">
                        {person.cc}
                      </td>
                      <td className="border border-gray-300 p-3">
                        {person.created_at}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminBillingPage;
