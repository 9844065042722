import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function InquiryPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_SERVER}inquiry`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name, email, phone }),
        }
      );

      if (response.ok) {
        window.location.reload();
      } else {
        console.error("Failed to send inquiry:", response.statusText);
        setError(response.statusText);
      }
    } catch (error) {
      console.error("Error sending inquiry:", error);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen py-8">
      <div className="container mx-auto">
        <h1 className="text-3xl font-semibold text-center mb-8">
          {t("inquiry.title")}
        </h1>
        <div className="max-w-lg mx-auto bg-white rounded-lg shadow-md overflow-hidden">
          <form onSubmit={handleSubmit} className="p-8">
            <div className="mb-6">
              <label
                htmlFor="name"
                className="block text-gray-700 font-bold mb-2"
              >
                {t("registerF.personal-info.name")}
              </label>
              <input
                id="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-input mt-1 block w-full rounded-md border-gray-300 py-3 px-4 border"
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="email"
                className="block text-gray-700 font-bold mb-2"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-input mt-1 block w-full rounded-md border-gray-300 py-3 px-4 border"
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="phone"
                className="block text-gray-700 font-bold mb-2"
              >
                {t("registerFuneralHome.createaccount.phone")}
              </label>
              <input
                id="phone"
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="form-input mt-1 block w-full rounded-md border-gray-300 py-3 px-4 border"
                required
              />
            </div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-[#384C67]  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {t("inquiry.submit")}
            </button>
          </form>
          {error}
        </div>
      </div>
    </div>
  );
}

export default InquiryPage;
