import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const FuneralHomeBillingPage = () => {
  const [billingData, setBillingData] = useState(null);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [filteredBillingData, setFilteredBillingData] = useState(null);
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));
  const uid = user.uid;
  const token = user.stsTokenManager.accessToken;
  useEffect(() => {
    // Fetch the billing data for the specific funeral home
    const fetchBillingData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL_SERVER}billing/${uid}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch billing data");
        }
        const data = await response.json();
        setBillingData(data);
      } catch (error) {
        console.error("Error fetching billing data:", error);
      }
    };

    fetchBillingData();
  }, [token, uid]);

  useEffect(() => {
    if (!billingData) return;
    // Filter billing data based on selected year and month
    const filteredData = Object.entries(billingData).map(
      ([funeralHomeId, { billingByYear }]) => ({
        funeralHomeId,
        years: Object.entries(billingByYear).map(([year, data]) => ({
          year,
          months: Object.entries(data).map(
            ([month, { totalBilling, persons }]) => ({
              month,
              totalBilling,
              persons: Object.entries(persons).map(([personId, personData]) => ({
                ...personData,
                // Format created_at to dd/mm/yyyy
                created_at: new Date(personData.created_at).toLocaleDateString('en-GB')
              }))
            })
          ),
        })),
      })
    );
    setFilteredBillingData(filteredData);
  }, [selectedYear, selectedMonth, billingData]);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setSelectedMonth(""); // Reset selected month when year changes
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  if (!filteredBillingData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4 overflow-hidden bg-white py-24 sm:py-32">
      <h1 className="text-3xl font-bold mb-4">{t("funeral.billing.title")}</h1>
      <div className="flex space-x-4 mb-4">
        <div>
          <label htmlFor="year" className="block">
            {t("funeral.billing.selectyear")}
          </label>
          <select
            id="year"
            value={selectedYear}
            onChange={handleYearChange}
            className="rounded border p-2"
          >
            <option value=""> {t("funeral.billing.selectyear-select")}</option>
            {filteredBillingData.map(({ years }) =>
              years.map(({ year }) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))
            )}
          </select>
        </div>
        <div>
          <label htmlFor="month" className="block">
            {t("funeral.billing.selectmonth")}
          </label>
          <select
            id="month"
            value={selectedMonth}
            onChange={handleMonthChange}
            disabled={!selectedYear}
            className="rounded border p-2"
          >
            <option value=""> {t("funeral.billing.selectmonth-select")}</option>
            {selectedYear &&
              filteredBillingData
                .find(({ years }) =>
                  years.some(({ year }) => year === selectedYear)
                )
                .years.find(({ year }) => year === selectedYear)
                .months.map(({ month }) => (
                  <option key={month} value={month}>
                   {t(`${month}`)}
                  </option>
                ))}
          </select>
        </div>
      </div>
      {selectedYear && selectedMonth && (
        <div>
          <h2 className="text-2xl font-bold mb-2">
            {selectedYear} - {t(`${selectedMonth}`)} 
          </h2>
          <p className="mb-2">
                  {t("funeral.billing.title.bill")}{" "}
            {
              filteredBillingData
                .find(({ years }) =>
                  years.some(({ year }) => year === selectedYear)
                )
                .years.find(({ year }) => year === selectedYear)
                .months.find(({ month }) => month === selectedMonth)
                .totalBilling
            }{" "}
            euros
          </p>

          <div className="flex flex-col space-y-4">
            {Object.entries(
              filteredBillingData
                .find(({ years }) =>
                  years.some(({ year }) => year === selectedYear)
                )
                .years.find(({ year }) => year === selectedYear)
                .months.find(({ month }) => month === selectedMonth).persons
            ).map(([personId, personData]) => (
              <div key={personId} className="border p-4">
                <p className="font-bold">{personData.name}</p>
                <p>CC: {personData.cc}</p>
                <p>  {t("funeral.billing.title.created_at")} {personData.created_at}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FuneralHomeBillingPage;
