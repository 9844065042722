// src/components/NotFound.js

import React from 'react';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
    const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-800">{t('notfound.title')}</h1>
        <p className="mt-2 text-lg text-gray-600">{t('notfound.text')}</p>

      </div>
    </div>
  );
};

export default NotFound;
