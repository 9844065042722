import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useTranslation } from "react-i18next";
import { PhotoIcon } from "@heroicons/react/24/solid";
import ConfirmationDialog from "../../Dialog/ConfirmationDialog";
import Region from "../../Region";

const EditProfile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [fiscalName, setFiscalName] = useState("");
  const [nif, setNif] = useState("");
  const [photo, setPhoto] = useState("");
  const [address, setAddress] = useState("");
  const [error, setError] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const uid = user.uid;
  const token = user.stsTokenManager.accessToken;
  const [showConfirmation, setShowConfirmation] = useState(false); // State for showing confirmation dialog
  const [selectedRegion, setSelectedRegion] = useState({
    country: "",
    district: "",
    city: "",
  });

  const handleRegionChange = (key, value) => {
    setSelectedRegion({ ...selectedRegion, [key]: value });
  };

  useEffect(() => {
    const fetchFuneralHome = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL_SERVER}getFuneralHomeByIdforProfile/${uid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setEmail(data.email);
          setName(data.name);
          setNif(data.nif);
          setPhoto(data.photo);
          setAddress(data.address);
          setFiscalName(data.fiscalName);
          setSelectedRegion({
            country: data.country,
            district: data.district,
            city: data.city,
          });
        } else {
          throw new Error("Failed to fetch funeral home data.");
        }
      } catch (error) {
        console.error("Error fetching funeral home data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFuneralHome();
  }, [uid, token]);

  const handleRegister = async (e) => {
    e.preventDefault();
    setShowConfirmation(true); // Show confirmation dialog
  };

  const handleUpdate = async (e) => {
    setShowConfirmation(false); // Close confirmation dialog

    try {
      // Upload new photo if it's changed
      let imageUrl = photo; // Default to the existing photo if not changed
      if (typeof photo === "object") {
        const storage = getStorage();
        const imageRef = ref(storage, `funeralHomes/${uid}`);
        await uploadBytes(imageRef, photo);
        imageUrl = await getDownloadURL(imageRef);
      }

      // Update funeral home data
      const response = await fetch(
        `${process.env.REACT_APP_URL_SERVER}updateFuneralHome/${uid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id: uid,
            name,
            photo: imageUrl,
            address,
            country: selectedRegion.country,
            district: selectedRegion.district,
            city: selectedRegion.city,
            fiscalName,
            email,
            nif,
          }),
        }
      );

      if (response.ok) {
        // Redirect the user to the desired page after successful update
        navigate("/list-people");
      } else {
        throw new Error("Failed to update funeral home.");
      }
    } catch (error) {
      console.error("Error updating funeral home:", error.message);
      setError(error.message);
    }
  };

  if (loading) {
    return <div className="text-center mt-4">{t("loading")}</div>;
  }

  return (
    <div className="mx-auto max-w-2xl p-6 bg-white">
      <ConfirmationDialog
        open={showConfirmation}
        setOpen={setShowConfirmation}
        confirmAction={handleUpdate}
        title="Atualizar Perfil"
        subtile="Confirma que todos os dados inseridos são reais?"
        buttonText="Atualizar"
      />
      <h2 className="mt-10 text-2xl font-bold mb-4 text-center">
        {t("funeralHome.profile")}
      </h2>
      <form onSubmit={handleRegister} className="">
        <div className="border-b border-gray-900/10 pb-12">
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                htmlFor="username"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    readOnly
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-2 col-span-full">
            <label
              htmlFor="cover-photo"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("registerF.logo")}
            </label>
            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
              <div className="text-center">
                <PhotoIcon
                  className="mx-auto h-12 w-12 text-gray-300"
                  aria-hidden="true"
                />
                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                  >
                    <span> {t("registerF.upload-photo")}</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      accept="image/*" // This attribute ensures that only image files are allowed
                      className="sr-only"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file && file.type.startsWith("image/")) {
                          // Check if the file type starts with 'image/'
                          setPhoto(file);
                        } else {
                          // Handle invalid file type
                          console.error(
                            "Invalid file type. Please select an image."
                          );
                          setError(
                            "Invalid file type. Please select an image."
                          );
                        }
                      }}
                    />

                    {photo && (
                      <img
                        src={photo}
                        alt="Selected"
                        className="w-full h-auto mb-4"
                      />
                    )}
                    {error}
                  </label>
                </div>
                <p className="text-xs leading-5 text-gray-600">PNG, JPG</p>
                <p className="pl-1">
                  {photo ? photo.name : t("registerF.upload-photo.no-file")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            {t("registerF.personal-info")}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            {t("registerF.personal-info.desc")}
          </p>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("registerF.personal-info.name")}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  required
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("registerF.personal-info.name.fiscal")}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  required
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  value={fiscalName}
                  onChange={(e) => setFiscalName(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("registerF.personal-info.nif")}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  required
                  autoComplete="family-name"
                  value={nif}
                  onChange={(e) => setNif(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-full">
              <Region
                country={selectedRegion.country}
                district={selectedRegion.district}
                city={selectedRegion.city}
                onChange={(key, value) => handleRegionChange(key, value)}
                putParish={false}
              />
            </div>

            <div className="col-span-full">
              <label
                htmlFor="street-address"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("registerF.personal-info.address")}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="street-address"
                  id="street-address"
                  required
                  autoComplete="street-address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>

        {error && <div className="text-red-500 mb-4 col-span-2">{error}</div>}
        <button
          type="submit"
          className="flex w-full justify-center rounded-md bg-[#384C67]  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-20"
        >
          {t("funeralHome.update")}
        </button>
      </form>
      {error && <div className="text-red-500 mb-4 col-span-2">{error}</div>}
    </div>
  );
};

export default EditProfile;
