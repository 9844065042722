import React, { useState } from "react";

const InviteFuneralHomes = () => {
  const [email, setEmail] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.stsTokenManager.accessToken;
  const [error, setError] = useState(null);

  const handleInvite = async (e) => {
    e.preventDefault();
    try {
      // Update funeral home data
      const response = await fetch(
        `${process.env.REACT_APP_URL_SERVER}invite`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },body: JSON.stringify({
            email,
          })
        }
      );

      if (response.ok) {
        // Redirect the user to the desired page after successful update
        window.location.reload()
      } else {
        throw new Error("Failed to update funeral home.");
      }
    } catch (error) {
      console.error("Error updating funeral home:", error.message);
      setError(error.message);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Convidar Funerárias a Aderir</h1>
      <form onSubmit={handleInvite}>
        <div className="mb-4">
          <label htmlFor="email" className="block mb-2">
            Email:
          </label>
          <input
            type="email"
            id="email"
            className="border rounded p-2 w-full"
            placeholder="Enter email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
              </div>
              <p>{error}</p>
        <button
          type="submit"
          className="bg-blue-500 text-white rounded py-2 px-4 hover:bg-blue-600 transition-colors"
        >
          Send Invitation
        </button>
      </form>
    </div>
  );
};

export default InviteFuneralHomes;
