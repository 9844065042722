import { useState } from "react";
import { auth } from "../firebase";
import { sendPasswordResetEmail } from "@firebase/auth";
import { useTranslation } from "react-i18next";

const RecoveryPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent successfully.");
      setTimeout(() => {
        setMessage(""); // Clear the message after a few seconds
        window.location.href = "/"; // Redirect to the home page
      }, 3000); // 3000 milliseconds = 3 seconds
    } catch (error) {
      console.error("Error sending password reset email:", error);
      setError("Error sending password reset email. Please try again later.");
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-white p-8 rounded shadow-md w-full sm:w-96">
        <h2 className="text-2xl font-bold mb-4">{t("recoverpassowrd.title")}</h2>
        {message && <div className="text-green-500 mb-4">{message}</div>}
        {error && <div className="text-red-500 mb-4">Error: {error}</div>}
        <form onSubmit={handleResetPassword}>
          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="email">Email:</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            />
          </div>
          <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600">{t("recoverpassowrd.reset")}</button>
        </form>
      </div>
    </div>
  );
};

export default RecoveryPassword;
