import React, { useEffect, useState } from "react";
import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const About = () => {
  const { t } = useTranslation();
  const [imageSrc, setImageSrc] = useState("");

  const features = [
    {
      name: t("features.streamlineCommunication"),
      description: t("features.streamlineCommunicationDescription"),
      icon: CloudArrowUpIcon,
    },
    {
      name: t("features.digitalTributes"),
      description: t("features.digitalTributesDescription"),
      icon: LockClosedIcon,
    },
    {
      name: t("features.centralizedInformation"),
      description: t("features.centralizedInformationDescription"),
      icon: ServerIcon,
    },
  ];

  useEffect(() => {
    const imageUrl =
      "https://firebasestorage.googleapis.com/v0/b/pdm2024-2e150.appspot.com/o/general%2F174263563_padded_logo.png?alt=media&token=f85eeb16-52c7-49b8-85eb-17ab41135dd7";
    const cachedImage = localStorage.getItem("aboutImage");

    if (cachedImage) {
      setImageSrc(cachedImage);
    } else {
      // If the image is not cached, fetch it and cache it
      setImageSrc(imageUrl);
      localStorage.setItem("aboutImage", imageUrl);
    }
  }, []);

  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <Helmet>
        <title>{t("aboutUs")}</title>
        <meta name="description" content={t("description")} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Sobre Nós" />
        <meta property="og:description" content={t("description")} />
        <meta
          property="og:url"
          content="https://portaldasmemorias.com/aboutUs"
        />
        <meta property="og:site_name" content="Portal das Memórias" />
        <link rel="canonical" href="https://portaldasmemorias.com/aboutUs" />
      </Helmet>

      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {t("about.title")}
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon
                        className="absolute left-1 top-1 h-5 w-5 text-[#384C67]"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          {imageSrc && (
            <img
              src={imageSrc}
              alt="Logo"
              className="w-full rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-auto sm:max-w-[calc(50vw - 1rem)]"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default About;
