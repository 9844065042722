import React, { useState } from "react";
import PrivacyPolacy from "./PrivacyPolicy";
import TermsModal from "./TermsModal";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [showTermsModal, setShowTermsModal] = useState(false);
  const handleTermsModalToggle = () => {
    setShowTermsModal(!showTermsModal);
  };
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const handleshowPolicyModalToggle = () => {
    setShowPolicyModal(!showPolicyModal);
  };
    const { t } = useTranslation();
  return (
    <footer className="bg-white text-black py-6 border ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-wrap justify-between">
          <div className="text-center w-full md:w-1/3 lg:w-1/4 mb-4 md:mb-0">
            <p className="text-md"> Portal das Memórias &copy;2024 </p>
          </div>
          <div className="text-center w-full md:w-1/3 lg:w-1/4 mb-4 md:mb-0">
            <span className="ml-2">
              <button onClick={handleTermsModalToggle}>
                {t("terms")}
              </button>
            </span>
            <TermsModal
              isOpen={showTermsModal}
              onClose={handleTermsModalToggle}
            />
          </div>
          <div className="text-center w-full md:w-1/3 lg:w-1/4 mb-4 md:mb-0">
            <span className="ml-2">
              <button onClick={handleshowPolicyModalToggle}>
                  {t("privacy-policy")}
              </button>
            </span>
            <PrivacyPolacy
              isOpen={showPolicyModal}
              onClose={handleshowPolicyModalToggle}
            />
          </div>
          <div className="text-center w-full md:w-1/3 lg:w-1/4 mb-4 md:mb-0">
            <p className="text-md">pmemoriescompany@gmail.com</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
