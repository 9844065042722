import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Filter from "./Filter";
import { differenceInYears } from "date-fns";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

function LastNews() {
  const [people, setPeople] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(12); // Number of items per page
  const { t } = useTranslation();
  const [filterParams, setFilterParams] = useState({}); // State for filter parameters

  const fetchData = async (url, params) => {
    try {
      const response = await axios.get(url, { params });
      const { data, totalPages } = response.data;
      return { data, totalPages };
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const fetchDataWithCache = async (
    url,
    cacheKey,
    setStateFunction,
    params
  ) => {
    try {
      const cachedData = localStorage.getItem(cacheKey);
      if (cachedData) {
        setStateFunction(JSON.parse(cachedData));
      }

      const { data } = await fetchData(url, params); // Fetch data with params
      if (data) {
        localStorage.setItem(cacheKey, JSON.stringify(data));
        setStateFunction(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

useEffect(() => {
  const baseUrl = process.env.REACT_APP_URL_SERVER;
  const getAllUrl = `${baseUrl}getAllPeople`;
  const filterUrl = `${baseUrl}filterPeople`;
  const cacheKey = "allPeople"; // Define cache key

  // Determine which URL to use based on whether filterParams are present
  const url = Object.keys(filterParams).length === 0 ? getAllUrl : filterUrl;

  // Fetch data without cache on initial render to unblock pagination
  fetchData(url, { page: currentPage, pageSize, ...filterParams }).then(
    ({ data, totalPages }) => {
      setPeople(data);
      setTotalPages(totalPages);
    }
  );

  // After initial fetch without cache, switch to cache-based fetching for subsequent updates
  fetchDataWithCache(url, cacheKey, setPeople, {
    page: currentPage,
    pageSize,
    ...filterParams,
  });
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [currentPage, pageSize, filterParams]);

  const handlePageChange = async (page) => {
    try {
      const url = `${process.env.REACT_APP_URL_SERVER}getAllPeople`;
      const params = { page, pageSize, ...filterParams };
      const { data, totalPages } = await fetchData(url, params);
      setPeople(data);
      setCurrentPage(page);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error or provide feedback to user
    }
  };

  return (
    <div className="bg-white">
      <Helmet>
        <title>{t("lastnews.title")}</title>
        <meta
          name="description"
          content="Últimas Notícias. Com o Portal das Memórias, é possível descobrir e recordar as pessoas que moldaram as nossas vidas. Neste espaço, é possível deixar tributos aos que nos deixaram, honrando suas memórias de forma significativa e duradoura, ou partilhar histórias que celebrem suas vidas e legados únicos"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Últimas Noticias" />
        <meta property="og:description" content={t("description")} />
        <meta
          property="og:url"
          content="https://portaldasmemorias.com/lastnews"
        />
        <meta property="og:site_name" content="Portal das Memórias" />
        <link rel="canonical" href="https://portaldasmemorias.com/lastnews" />
      </Helmet>

      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <Filter
          currentPage={currentPage}
          setPeople={setPeople}
          setCurrentPage={setCurrentPage}
          setTotalPages={setTotalPages}
          setFilterParams={setFilterParams}
        />

        <div className="mt-1 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-x-6 gap-y-10">
          {people.map((person) => {
            // Ensure people is always an array
            const deathYear = parseInt(person.death.split("-")[0]);
            const birthYear = parseInt(person.birthday.split("-")[0]);

            return (
              <Link key={person.id} to={`/person/${person.id}`}>
                <div className="group relative">
                  <div className="flex justify-center sm:justify-start">
                    <img
                      src={person.photo}
                      alt={person.name}
                      className="w-40 h-40 sm:w-80 sm:h-80 lg:w-100 lg:h-100 object-cover rounded-xl shadow-xl ring-1 ring-gray-400/10 mx-auto sm:mx-0"
                    />
                  </div>

                  <div className="mt-4 flex justify-between">
                    <div>
                      <h3 className="text-sm  font-medium text-gray-700">
                        <p>
                          <span
                            aria-hidden="true"
                            className="absolute inset-0"
                          />
                          {person.name}
                        </p>
                      </h3>
                    </div>
                  </div>
                  <p className="text-sm  text-gray-900">
                    {differenceInYears(
                      new Date(person.death),
                      new Date(person.birthday)
                    )}{" "}
                    {t("person.years")} , {birthYear} - {deathYear}
                  </p>

                  <p className="text-sm  text-gray-900">
                    {person.city.replace(" Municipality", "")}, {person.district}, {person.country}
                  </p>
                </div>
              </Link>
            );
          })}
        </div>

        {/* Tailwind CSS Pagination */}
        <div className="flex justify-between items-center border-t border-gray-200 px-4 py-3 sm:px-6">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 ${
              currentPage === 1
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-gray-50"
            }`}
          >
            <ChevronLeftIcon
              className="-ml-1 mr-2 h-5 w-5"
              aria-hidden="true"
            />
            {t("pagination.previous")}
          </button>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`ml-3 relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 ${
              currentPage === totalPages
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-gray-50"
            }`}
          >
            {t("pagination.next")}
            <ChevronRightIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
    </div>
  );
}

export default LastNews;
