import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useTranslation } from "react-i18next";
import { PhotoIcon } from "@heroicons/react/24/solid";
import ConfirmationDialog from "../../Dialog/ConfirmationDialog";
import Region from "../../Region";
import { differenceInYears } from "date-fns";

const AddPerson = () => {
  const [name, setName] = useState("");
  const [photo, setPhoto] = useState("");
  const [cc, setCC] = useState("");
  const [funeralDetails, setFuneralDetails] = useState({
    celebration: "",
    church: {
      address: "",
      time: "",
      day: "",
    },
    funeral: {
      address: "",
      time: "",
      day: "",
    },
    funeral_services: "",
    additional_info: "",
  });

  const [birthday, setBirthday] = useState("");
  const [death, setDeath] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false); // State for showing confirmation dialog
  const [selectedRegion, setSelectedRegion] = useState({
    country: "",
    district: "",
    city: "",
    parish: "",
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleRegionChange = (key, value) => {
    setSelectedRegion({ ...selectedRegion, [key]: value });
  };
  const handleRegister = async (e) => {
    e.preventDefault();
    setShowConfirmation(true); // Show confirmation dialog
  };

  const handleSubmit = async (e) => {
    setShowConfirmation(false); // Close confirmation dialog
    const wrapText = (ctx, text, x, y, maxWidth, lineHeight) => {
      const words = text.split(" ");
      let line = "";
      for (let n = 0; n < words.length; n++) {
        let testLine = line + words[n] + " ";
        let metrics = ctx.measureText(testLine);
        let testWidth = metrics.width;
        if (testWidth > maxWidth && n > 0) {
          ctx.fillText(line, x, y);
          line = words[n] + " ";
          y += lineHeight;
        } else {
          line = testLine;
        }
      }
      ctx.fillText(line, x, y);
    };
    // Function to compress the image
    function compressImage(image) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = 370; // Adjust the width as needed
            canvas.height = (canvas.width / img.width) * img.height;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            canvas.toBlob(
              (blob) => {
                resolve(blob);
              },
              "image/jpeg", // Adjust the image format if needed
              0.8 // Adjust the quality (0.8 is a good starting point)
            );
          };
          img.onerror = (error) => reject(error);
        };
      });
    }

function newFacebookImage(imageFile) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Set the canvas dimensions to higher resolution
    const scale = 2;
    canvas.width = 600 * scale;
    canvas.height = 315 * scale;

    const mainImage = new Image();
    mainImage.src = "/main_template.png"; // Ensure this path is correct

    const age = differenceInYears(death, birthday);
    const location = selectedRegion.parish + ", " + selectedRegion.city.replace(" Municipality", "");

    mainImage.onload = () => {
      // Draw main image scaled to canvas size
      ctx.drawImage(mainImage, 0, 0, canvas.width, canvas.height);

      const reader = new FileReader();
      reader.readAsDataURL(imageFile);

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          // Draw uploaded image on the right side
          ctx.drawImage(img, 350 * scale, 50 * scale, 200 * scale, 200 * scale);

          // Add text on the left side with scaled font sizes and positions
          ctx.fillStyle = "black";
          ctx.font = `bolder ${22 * scale}px Arial`;
          wrapText(ctx, name, 30 * scale, 60 * scale, 300 * scale, 24 * scale);
          ctx.font = `${18 * scale}px Arial`;
          wrapText(ctx, age + " anos", 30 * scale, 150 * scale, 300 * scale, 22 * scale);
          ctx.font = `${14 * scale}px Arial`;
          wrapText(ctx, location, 30 * scale, 170 * scale, 300 * scale, 18 * scale);

          // Convert the temp canvas to a blob and resolve the promise
          canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            "image/jpeg", // Adjust the image format if needed
            0.8 // Adjust the quality (0.8 is a good starting point)
          );
        };

        img.onerror = (error) => {
          console.error("Failed to load uploaded image:", error);
          reject(new Error(`Failed to load uploaded image: ${error.message}`));
        };
      };

      reader.onerror = (error) => {
        console.error("Failed to read image file:", error);
        reject(new Error(`Failed to read image file: ${error.message}`));
      };
    };

    mainImage.onerror = (error) => {
      console.error("Failed to load main image:", error);
      reject(new Error(`Failed to load main image: ${error.message}`));
    };
  });
}


    function generateRandomId(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_#";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    }
    try {
      // Compress the image before uploading
      const compressedImage = await compressImage(photo);

      // Upload compressed image to Firebase Storage
      const storage = getStorage();
      const randomId = generateRandomId(30);
      const imageRef = ref(storage, `person/${randomId}`);
      await uploadBytes(imageRef, compressedImage);

      const facebookImage = await newFacebookImage(photo);
      const imageRefFacebook = ref(storage, `thumbnails/${randomId}`);
      await uploadBytes(imageRefFacebook, facebookImage);
      // Get download URL for the compressed image
      const imageUrl = await getDownloadURL(imageRef);
      const facebookUrl = await getDownloadURL(imageRefFacebook);


      // Get user information
      const user = JSON.parse(localStorage.getItem("user"));
      const uid = user.uid;
      const token = user.stsTokenManager.accessToken;
      // Call to addPerson API
      const response = await fetch(
        process.env.REACT_APP_URL_SERVER + "addPerson",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name,
            photo: imageUrl,
            country: selectedRegion.country,
            district: selectedRegion.district,
            city: selectedRegion.city,
            parish: selectedRegion.parish,
            idFuneralHome: uid,
            funeralDetails,
            birthday,
            death,
            thumbnail:facebookUrl,
            description,
            cc,
          }),
        }
      );
      console.log(error);
      console.log(response);
      if (response.ok) {
        // Redirect the user to the desired page after successful registration
        navigate("/lastnews");
      } else {
        throw new Error(
          "Esta pessoa já existe na plataforma. Em caso de necessidade contacte o suporte"
        );
      }
    } catch (error) {
      console.error("Error registering user:", error.message);
      setError(error.message);
    }
  };

  return (
    <div className="mx-auto max-w-2xl p-6 bg-white">
      <ConfirmationDialog
        open={showConfirmation}
        setOpen={setShowConfirmation}
        confirmAction={handleSubmit}
        title={t("addperson.modal.title")}
        subtile={t("addperson.modal.subtile")}
        buttonText={t("addperson.modal.button")}
      />
      <h2 className="mt-10 text-2xl font-bold mb-4 text-center">
        {" "}
        {t("registerF.title")}
      </h2>
      <form onSubmit={handleRegister} className="">
        <div className="border-b border-gray-900/10 pb-12">
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-full">
              <label
                htmlFor="username"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("registerF.personal-info.name")}
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="username"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("register.personal-info.cc")}
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="number"
                    name="cc"
                    id="cc"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    value={cc}
                    required
                    onChange={(e) => setCC(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 col-span-full">
            <label
              htmlFor="cover-photo"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("register.personal-info.photo")}
            </label>
            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
              <div className="text-center">
                <PhotoIcon
                  className="mx-auto h-12 w-12 text-gray-300"
                  aria-hidden="true"
                />
                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                  >
                    <span> {t("registerF.upload-photo")}</span>
                    <input
                      required
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      accept="image/*" // This attribute ensures that only image files are allowed
                      className="sr-only"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file && file.type.startsWith("image/")) {
                          // Check if the file type starts with 'image/'
                          setPhoto(file);
                          setError("");
                        } else {
                          // Handle invalid file type
                          console.error(
                            "Invalid file type. Please select an image."
                          );
                          setError(
                            "Invalid file type. Please select an image."
                          );
                        }
                      }}
                    />

                    {photo && (
                      <img
                        src={photo}
                        alt="Selected"
                        className="w-full h-auto mb-4"
                      />
                    )}
                    {error}
                  </label>
                </div>
                <p className="text-xs leading-5 text-gray-600">PNG, JPG</p>
                <p className="pl-1">
                  {photo ? photo.name : t("registerF.upload-photo.no-file")}
                </p>
              </div>
            </div>
            <div className="col-span-full mt-2">
              <Region
                country={selectedRegion.country}
                district={selectedRegion.district}
                city={selectedRegion.city}
                parish={selectedRegion.parish}
                onChange={(key, value) => handleRegionChange(key, value)}
                putParish={true}
              />
            </div>
          </div>
        </div>
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            {t("registerF.personal-info")}
          </h2>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3 sm:col-start-1">
              <label
                htmlFor="city"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("register.personal-info.birth")}
              </label>
              <div className="mt-2">
                <input
                  required
                  type="date"
                  name="birthday"
                  id="birthday"
                  value={birthday}
                  onChange={(e) => setBirthday(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="region"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("register.personal-info.death")}
              </label>
              <div className="mt-2">
                <input
                  required
                  type="date"
                  name="death"
                  value={death}
                  onChange={(e) => setDeath(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-full">
              <label
                htmlFor="street-address"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("register.personal-info.description")}
              </label>
              <div className="mt-2">
                <textarea
                  rows="4"
                  cols="50"
                  required
                  name="description"
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            {t("register.personal-info.funeral.title")}{" "}
          </h2>{" "}
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="church-address"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("register.personal-info.funeralday")}
              </label>
              <div className="mt-2">
                <input
                  type="date"
                  name="funeral-day"
                  id="funeral-day"
                  value={funeralDetails.funeral.day}
                  onChange={(e) =>
                    setFuneralDetails({
                      ...funeralDetails,
                      funeral: {
                        ...funeralDetails.funeral,
                        day: e.target.value,
                      },
                    })
                  }
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            {/*    <div className="sm:col-span-3">
              <label
                htmlFor="church-time"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("register.personal-info.funeralhour")}
              </label>
              <div className="mt-2">
                <input
                  type="time"
                  name="funeral-time"
                  id="funeral-time"
                  value={funeralDetails.funeral.time}
                  onChange={(e) =>
                    setFuneralDetails({
                      ...funeralDetails,
                      funeral: {
                        ...funeralDetails.funeral,
                        time: e.target.value,
                      },
                    })
                  }
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div> */}
            <div className="sm:col-span-full">
              <label
                htmlFor="church-day"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("register.personal-info.funeraladdress")}{" "}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="church-day"
                  id="church-day"
                  value={funeralDetails.funeral.address}
                  onChange={(e) =>
                    setFuneralDetails({
                      ...funeralDetails,
                      funeral: {
                        ...funeralDetails.funeral,
                        address: e.target.value,
                      },
                    })
                  }
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className="border-b border-gray-900/10 pb-12"></div>
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              {t("register.personal-info.service")}
            </h2>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-full">
                <label
                  htmlFor="church-day"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("register.personal-info.service-type")}
                </label>
                <div className="mt-2">
                  <select
                    name="celebration"
                    id="celebration"
                    value={funeralDetails.celebration}
                    onChange={(e) =>
                      setFuneralDetails({
                        ...funeralDetails,
                        celebration: e.target.value,
                      })
                    }
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="">
                      {t("register.personal-info.service-typeselect")}
                    </option>
                    <optgroup
                      label={t("register.personal-info.cristianservices")}
                    >
                      <option value={t("register.personal-info.burialmass")}>
                        {t("register.personal-info.burialmass")}
                      </option>
                      <option value={t("register.personal-info.sevendaymass")}>
                        {" "}
                        {t("register.personal-info.sevendaymass")}
                      </option>
                      <option value={t("register.personal-info.mensalmass")}>
                        {" "}
                        {t("register.personal-info.mensalmass")}
                      </option>
                      <option value={t("register.personal-info.yearmass")}>
                        {t("register.personal-info.yearmass")}
                      </option>
                      <option
                        value={t("register.personal-info.masspresentbody")}
                      >
                        {t("register.personal-info.masspresentbody")}
                      </option>
                      <option value="Câmara Ardente">Câmara Ardente</option>
                      <option value="Celebração da Palavra">
                        Celebração da Palavra
                      </option>
                      {/* Add more Christian options here */}
                    </optgroup>
                    <optgroup label={t("register.personal-info.other")}>
                      <option value=""></option>
                      <option value="Velório"> Velório </option>
                      <option value="Culto de Memória">Culto de Memória</option>
                      <option value="Memorial Service">Memorial Service</option>
                      <option value="Shiva">Shiva</option>
                      <option value="Kaddish">Kaddish</option>
                      <option value="Salat al-Janazah">Salat al-Janazah</option>
                      <option value="Buddhist Funeral">Buddhist Funeral</option>
                      <option value="Hindu Antim Sanskar">
                        Hindu Antim Sanskar
                      </option>
                      {/* Add more options for other religions */}
                    </optgroup>
                  </select>
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="church-address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("register.personal-info.service-day")}
                </label>
                <div className="mt-2">
                  <input
                    type="date"
                    name="church-address"
                    id="church-address"
                    value={funeralDetails.church.day}
                    onChange={(e) =>
                      setFuneralDetails({
                        ...funeralDetails,
                        church: {
                          ...funeralDetails.church,
                          day: e.target.value,
                        },
                      })
                    }
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="church-time"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("register.personal-info.service-hour")}
                </label>
                <div className="mt-2">
                  <input
                    type="time"
                    name="church-time"
                    id="church-time"
                    value={funeralDetails.church.time}
                    onChange={(e) =>
                      setFuneralDetails({
                        ...funeralDetails,
                        church: {
                          ...funeralDetails.church,
                          time: e.target.value,
                        },
                      })
                    }
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-full">
                <label
                  htmlFor="church-day"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("register.personal-info.service-address")}
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="church-day"
                    id="church-day"
                    value={funeralDetails.church.address}
                    onChange={(e) =>
                      setFuneralDetails({
                        ...funeralDetails,
                        church: {
                          ...funeralDetails.church,
                          address: e.target.value,
                        },
                      })
                    }
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {/*       <div className="sm:col-span-full">
                  <label
                    htmlFor="funeral-services"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("register.personal-info.service-edital")}
                  </label>
                  <div className="mt-2">
                    <textarea
                      name="funeral-services"
                      id="funeral-services"
                      value={funeralDetails.funeral_services}
                      onChange={(e) =>
                        setFuneralDetails({
                          ...funeralDetails,
                          funeral_services: e.target.value,
                        })
                      }
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    ></textarea>
                  </div>
                </div> */}
              <div className="sm:col-span-full">
                <label
                  htmlFor="additional-info"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("register.personal-info.service-aditional")}
                </label>
                <div className="mt-2">
                  <textarea
                    name="additional-info"
                    id="additional-info"
                    value={funeralDetails.additional_info}
                    onChange={(e) =>
                      setFuneralDetails({
                        ...funeralDetails,
                        additional_info: e.target.value,
                      })
                    }
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        {error && <div className="text-red-500 mb-4 col-span-2">{error}</div>}
        <button
          type="submit"
          className="flex w-full justify-center rounded-md bg-[#384C67]  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-20"
        >
          {t("addperson.modal.title")}
        </button>
      </form>
    </div>
  );
};

export default AddPerson;
