/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Theme2 from "./Themes/Theme2";
import Theme3 from "./Themes/Theme3";
import Theme4 from "./Themes/Theme4";
import Theme5 from "./Themes/Theme5";
import Theme6 from "./Themes/Theme6";
import Theme7 from "./Themes/Theme7";
import Theme8 from "./Themes/Theme8";
import Theme9 from "./Themes/Theme9";
import Theme10 from "./Themes/Theme10";
import Theme1 from "./Themes/Theme1";
import { differenceInYears } from "date-fns";
import { useParams } from "react-router-dom";

const GenerateEdital = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const uid = user.uid;
  const token = user.stsTokenManager.accessToken;
  const { id } = useParams();
  const [name, setName] = useState("");
  const [photo, setPhoto] = useState("");
  const [loading, setLoading] = useState(true);
  const [city, setCity] = useState("");
  const [parish, setParish] = useState("");
  const [birthday, setBirthday] = useState("");
  const [death, setDeath] = useState("");
  const [error, setError] = useState(null);
  const [funeralDetails, setFuneralDetails] = useState({
    celebration: "",
    church: {
      address: "",
      time: "",
      day: "",
    },
    funeral: {
      address: "",
      time: "",
      day: "",
    },
    funeral_services: "",
    additional_info: "",
  });
  const [emailFuneralHome, setEmailFuneralHome] = useState("");
  const [nameFuneralHome, setNameFuneralHome] = useState("");
  const [photoFuneralHome, setPhotoFuneralHome] = useState("");
  const [addressFuneralHome, setAddressFuneralHome] = useState("");
  const [selectedRegionFuneralHome, setSelectedRegionFuneralHome] = useState({
    country: "",
    district: "",
    city: "",
  });
  const [editalParish, setEditalParish] = useState("");

  const [phoneFuneralHome, setPhoneFuneralHome] = useState("");

  useEffect(() => {
    const fetchPerson = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL_SERVER}/getPersonbyId/${id}`
        );
        if (response.ok) {
          const data = await response.json();
          setName(data.name);
          setParish(data.parish);
          setCity(data.city);
          setPhoto(data.photo);
          setBirthday(data.birthday);
          setDeath(data.death);
          setFuneralDetails(data.funeralDetails);
          setEditalParish(parish + ", " + city.replace(" Municipality", " "));
        } else {
          throw new Error("Failed to fetch person");
        }
      } catch (error) {
        console.error("Error fetching person:", error);
        setError(error.message);
      }
    };
    const fetchFuneralHome = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL_SERVER}getFuneralHomeByIdforProfile/${uid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setEmailFuneralHome(data.email);
          setNameFuneralHome(data.name);
          setPhotoFuneralHome(data.photo);
          setAddressFuneralHome(data.address);
          setSelectedRegionFuneralHome({
            country: data.country,
            district: data.district,
            city: data.city,
          });
          setPhoneFuneralHome(data.phone);
        } else {
          throw new Error("Failed to fetch funeral home data.");
        }
      } catch (error) {
        console.error("Error fetching funeral home data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFuneralHome();

    fetchPerson();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, uid, token, city]);

  const age = differenceInYears(death, birthday);

  const day = funeralDetails.funeral.day.substring(8, 10);

  const themes = [
    { id: "theme2", preview: "/2.png" },
    { id: "theme3", preview: "/3.png" },
    { id: "theme4", preview: "/4.png" },
    { id: "theme5", preview: "/5.png" },
    { id: "theme6", preview: "/6.png" },
    { id: "theme7", preview: "/7.png" },
    { id: "theme8", preview: "/8.png" },
    { id: "theme9", preview: "/9.png" },
    { id: "theme10", preview: "/10.png" },
    { id: "theme1", preview: "/1.png" },
  ];
  const printRef = useRef();
  const [editalText, setEditalText] = useState("");

  const [selectedTheme, setSelectedTheme] = useState("theme2");
  const [selectedService, setSelectedService] = useState("");

  const handleGeneratePdf = async () => {
    try {
      // Connection successful, proceed with generating PDF
      const element = printRef.current;
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        allowTaint: true,
      });

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      console.log(imgProps);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(name + "_edital.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
      // Handle error (e.g., display an error message to the user)
    }
  };

  const initialText =
    "A família cumpre o doloroso dever de participar o falecimento do seu familiar e convida todas as pessoas das suas relações e amizades, a estarem presentes ";

  const handleServiceChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedService(selectedOption);
    switch (selectedOption) {
      case "funeral_and_religious_service":
        setEditalText(
          initialText +
            "dia <b><u>" +
            day +
            "</u></b>, pelas <b><u>" +
            funeralDetails.church.time +
            "</u></b> horas da <b><u>" +
            funeralDetails.church.address +
            "</u></b> para o <b><u>" +
            funeralDetails.funeral.address +
            "</b></u>"
        );
        break;
      case "religious_service":
        setEditalText(
          initialText +
            "dia <b><u>" +
            day +
            "</u></b>, pelas <b><u>" +
            funeralDetails.church.time +
            "</u></b> horas na <b><u>" +
            funeralDetails.church.address +
            "</u></b>"
        );
        break;
      case "information":
        setEditalText("Personalized text");
        break;
      case "personalized":
        setEditalText("Personalized text");
        break;
      default:
        setEditalText("");
    }
  };

  const handleThemeChange = (theme) => {
    setSelectedTheme(theme);
  };

  const renderSelectedTheme = () => {
    switch (selectedTheme) {
      case "theme2":
        return (
          <Theme2
            name={name}
            age={age}
            editalText={editalText}
            editalParish={editalParish}
            photo={photo}
            emailFuneralHome={emailFuneralHome}
            nameFuneralHome={nameFuneralHome}
            photoFuneralHome={photoFuneralHome}
            addressFuneralHome={addressFuneralHome}
            selectedRegionFuneralHome={selectedRegionFuneralHome}
            phone={phoneFuneralHome}
          />
        );
      case "theme3":
        return (
          <Theme3
            name={name}
            age={age}
            editalText={editalText}
            editalParish={editalParish}
            photo={photo}
            emailFuneralHome={emailFuneralHome}
            nameFuneralHome={nameFuneralHome}
            photoFuneralHome={photoFuneralHome}
            addressFuneralHome={addressFuneralHome}
            selectedRegionFuneralHome={selectedRegionFuneralHome}
            phone={phoneFuneralHome}
          />
        );
      case "theme4":
        return (
          <Theme4
            name={name}
            age={age}
            editalText={editalText}
            editalParish={editalParish}
            emailFuneralHome={emailFuneralHome}
            nameFuneralHome={nameFuneralHome}
            photoFuneralHome={photoFuneralHome}
            addressFuneralHome={addressFuneralHome}
            selectedRegionFuneralHome={selectedRegionFuneralHome}
            phone={phoneFuneralHome}
            photo={photo}
          />
        );
      case "theme5":
        return (
          <Theme5
            name={name}
            age={age}
            editalText={editalText}
            editalParish={editalParish}
            emailFuneralHome={emailFuneralHome}
            nameFuneralHome={nameFuneralHome}
            photoFuneralHome={photoFuneralHome}
            addressFuneralHome={addressFuneralHome}
            selectedRegionFuneralHome={selectedRegionFuneralHome}
            phone={phoneFuneralHome}
            photo={photo}
          />
        );
      case "theme6":
        return (
          <Theme6
            name={name}
            age={age}
            editalText={editalText}
            editalParish={editalParish}
            emailFuneralHome={emailFuneralHome}
            nameFuneralHome={nameFuneralHome}
            photoFuneralHome={photoFuneralHome}
            addressFuneralHome={addressFuneralHome}
            selectedRegionFuneralHome={selectedRegionFuneralHome}
            phone={phoneFuneralHome}
            photo={photo}
          />
        );
      case "theme7":
        return (
          <Theme7
            name={name}
            age={age}
            editalText={editalText}
            editalParish={editalParish}
            emailFuneralHome={emailFuneralHome}
            nameFuneralHome={nameFuneralHome}
            photoFuneralHome={photoFuneralHome}
            addressFuneralHome={addressFuneralHome}
            selectedRegionFuneralHome={selectedRegionFuneralHome}
            phone={phoneFuneralHome}
            photo={photo}
          />
        );
      case "theme8":
        return (
          <Theme8
            name={name}
            age={age}
            editalText={editalText}
            editalParish={editalParish}
            emailFuneralHome={emailFuneralHome}
            nameFuneralHome={nameFuneralHome}
            photoFuneralHome={photoFuneralHome}
            addressFuneralHome={addressFuneralHome}
            selectedRegionFuneralHome={selectedRegionFuneralHome}
            phone={phoneFuneralHome}
            photo={photo}
          />
        );
      case "theme9":
        return (
          <Theme9
            name={name}
            age={age}
            editalText={editalText}
            editalParish={editalParish}
            emailFuneralHome={emailFuneralHome}
            nameFuneralHome={nameFuneralHome}
            photoFuneralHome={photoFuneralHome}
            addressFuneralHome={addressFuneralHome}
            selectedRegionFuneralHome={selectedRegionFuneralHome}
            phone={phoneFuneralHome}
            photo={photo}
          />
        );
      case "theme10":
        return (
          <Theme10
            name={name}
            age={age}
            editalText={editalText}
            editalParish={editalParish}
            emailFuneralHome={emailFuneralHome}
            nameFuneralHome={nameFuneralHome}
            photoFuneralHome={photoFuneralHome}
            addressFuneralHome={addressFuneralHome}
            selectedRegionFuneralHome={selectedRegionFuneralHome}
            phone={phoneFuneralHome}
            photo={photo}
          />
        );
      case "theme1":
        return (
          <Theme1
            name={name}
            age={age}
            editalText={editalText}
            editalParish={editalParish}
            emailFuneralHome={emailFuneralHome}
            nameFuneralHome={nameFuneralHome}
            photoFuneralHome={photoFuneralHome}
            addressFuneralHome={addressFuneralHome}
            selectedRegionFuneralHome={selectedRegionFuneralHome}
            phone={phoneFuneralHome}
            photo={photo}
          />
        );
      default:
        return (
          <Theme2
            name={name}
            age={age}
            editalText={editalText}
            editalParish={editalParish}
            emailFuneralHome={emailFuneralHome}
            nameFuneralHome={nameFuneralHome}
            photoFuneralHome={photoFuneralHome}
            addressFuneralHome={addressFuneralHome}
            selectedRegionFuneralHome={selectedRegionFuneralHome}
            phone={phoneFuneralHome}
          />
        );
    }
  };

  return (
    <div className="flex flex-col items-center">
      <h1 className="text-3xl font-semibold my-4"> </h1>
      <div className="flex">
        <div
          ref={printRef}
          className="relative w-[210mm] h-[297mm] bg-cover border-2 border-gray-300 mr-4"
        >
          {renderSelectedTheme()}
        </div>

        <div style={{ width: "600px" }} className="mt-20">
          <label className="font-semibold">Temas:</label>

          <div className="grid grid-cols-5 gap-4 mb-8">
            {themes.map((theme, index) => (
              <div
                key={theme.id}
                className="cursor-pointer"
                onClick={() => handleThemeChange(theme.id)}
              >
                <img
                  src={theme.preview}
                  alt={`Theme ${index + 1}`}
                  className="w-full h-30 object-cover rounded-lg shadow-lg"
                />
                <p className="text-center mt-2">{index + 1}</p>
              </div>
            ))}
          </div>
          <label className="font-semibold mb-2">Editar Freguesia:</label>

          <ReactQuill
            value={editalParish}
            onChange={setEditalParish}
            theme="snow"
            style={{ width: "100%", height: "50px", marginTop: "20px" }}
          />
          <div className="flex flex-col mb-5 mt-20">
            <label className="font-semibold mb-2">Texto:</label>
            <select
              onChange={handleServiceChange}
              className="p-2 border rounded-md"
            >
              <option value="">Selecionar Opção</option>
              <option value="funeral_and_religious_service">
                Funeral & Serviço Religioso
              </option>
              <option value="religious_service">Serviço Religioso</option>
              <option value="personalized">Personalizar</option>
            </select>
          </div>

          <ReactQuill
            value={editalText}
            onChange={setEditalText}
            theme="snow"
            style={{ width: "100%", height: "300px" }}
          />

          <button
            onClick={handleGeneratePdf}
            className="mt-40 px-5 py-2 bg-blue-500 text-white rounded"
          >
            Gerar PDF
          </button>
          <div className="flex flex-col mb-5 mt-10space-y-8">
            <div className="funeral-details">
              <h2 className="text-2xl font-bold mb-4">Detalhes do Funeral</h2>
              <p>
                <span className="font-semibold">Morada:</span>{" "}
                {funeralDetails.funeral.address}
              </p>
              <p>
                <span className="font-semibold">Data:</span>{" "}
                {funeralDetails.funeral.day}
              </p>
            </div>

            <div className="service-details">
              <h2 className="text-2xl font-bold mb-4">
                Detalhes do Serviço Religioso
              </h2>
              <p>{funeralDetails.celebration}</p>
              <p>
                <span className="font-semibold">Morada:</span>{" "}
                {funeralDetails.church.address}
              </p>
              <p>
                <span className="font-semibold">Hora:</span>{" "}
                {funeralDetails.church.time}
              </p>
              <p>
                <span className="font-semibold">Data:</span>{" "}
                {funeralDetails.church.day}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateEdital;
