/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import PrivacyPolacy from "../PrivacyPolicy";
import { useTranslation } from "react-i18next";

const ConsentBanner = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const handleshowPolicyModalToggle = () => {
    setShowPolicyModal(!showPolicyModal);
  };
  const { t } = useTranslation();
  return (
    <div className="fixed bottom-0 left-0 w-full bg-gray-800 text-white p-4 flex justify-between items-center">
      <p className="text-sm">
        {t("cookie_banner")}{" "}
        <span className="underline">
          <button onClick={handleshowPolicyModalToggle}>
            {t("cookie_banner.learn")}{" "}
          </button>
        </span>
        <PrivacyPolacy
          isOpen={showPolicyModal}
          onClose={handleshowPolicyModalToggle}
        />
      </p>
      <button
        onClick={giveCookieConsent}
        className="ml-4 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
      >
        {t("cookie_banner.button")}
      </button>
    </div>
  );
};

export default ConsentBanner;
