import React, { useEffect, useState } from "react";
import ResponsiveImage from "../ResponsiveImage";
import axios from "axios";

const Theme1 = ({
  name,
  location,
  age,
  editalText,
  photo,
  emailFuneralHome,
  nameFuneralHome,
  birthday,
  death,
  photoFuneralHome,
  phone,
}) => {
  const [photoUrl, setPhotoUrl] = useState("");
  const [funeralHomeURL, setfuneralHomeURL] = useState("");
    useEffect(() => {
    const fetchPhoto = async () => {
      try {
        // Fetch person's photo using axios
        const photoResponse = await axios.get(`${process.env.REACT_APP_URL_SERVER}proxy`, {
          params: {
            url: encodeURIComponent(photo),
            callback: 'handlePhoto', // Replace with your callback function name if needed
            xhr2: 'false', // Change to 'true' if using XMLHttpRequest level 2 support
          },
          responseType: 'text', // Response type set to handle text data
        });

        if (photoResponse.status === 200) {
          const dataUriMatch = photoResponse.data.match(/^handlePhoto\("data:image\/jpeg;base64,([^"]+)"\);$/);
          if (dataUriMatch && dataUriMatch.length === 2) {
            const extractedDataUri = `data:image/jpeg;base64,${dataUriMatch[1]}`;
            // Set the extracted data URI as the photoUrl state
            setPhotoUrl(extractedDataUri);
          } else {
            throw new Error("Failed to parse photo data URI");
          }
        } else {
          throw new Error("Failed to fetch photo");
        }
      } catch (error) {
        console.error("Error fetching photo:", error);
      }
    };

    fetchPhoto();
    }, [photo]); // Trigger fetchPhoto() when 'photo' prop changes
  
  
      useEffect(() => {
    const fetchPhotoFuneralHome = async () => {
      try {
        // Fetch person's photo using axios
        const photoFuneralHomeResponse = await axios.get(`${process.env.REACT_APP_URL_SERVER}proxy`, {
          params: {
            url: encodeURIComponent(photoFuneralHome),
            callback: 'handlePhoto', // Replace with your callback function name if needed
            xhr2: 'false', // Change to 'true' if using XMLHttpRequest level 2 support
          },
          responseType: 'text', // Response type set to handle text data
        });

        if (photoFuneralHomeResponse.status === 200) {
          const dataUriMatch2 = photoFuneralHomeResponse.data.match(/^handlePhoto\("data:image\/jpeg;base64,([^"]+)"\);$/);
          if (dataUriMatch2 && dataUriMatch2.length === 2) {
            const extractedDataUri2 = `data:image/jpeg;base64,${dataUriMatch2[1]}`;

            // Set the extracted data URI as the photoUrl state
            setfuneralHomeURL(extractedDataUri2);
          } else {
            throw new Error("Failed to parse photo data URI");
          }
        } else {
          throw new Error("Failed to fetch photo");
        }
      } catch (error) {
        console.error("Error fetching photo:", error);
      }
    };

    fetchPhotoFuneralHome();
  }, [photoFuneralHome]); // Trigger fetchPhoto() when 'photo' prop changes
  return (
    <div
      id="capture"
      className="relative w-[155mm] h-[120mm] bg-cover border border-gray-300"
      style={{ backgroundImage: "url('/pagela1.png')" }}
    >
      <div className="absolute inset-0 p-4 flex">
        <div className="w-1/2 flex flex-col justify-between">
          <div>
            <img
              src={photoUrl}
              alt="person"
              className="w-[190px] h-[190px] mx-auto mt-6 rounded"
            />
            <div className="text-left mt-[35px] ml-[20px] ">
              <p  className="mb-1 text-[10px]" >Lembrai-vos nas vossas orações da alma de:</p>
              <h1 className="font-bold mb-1 text-md w-[250px]">{name}</h1>
              <p className="text-xs mb-1">Nasceu em: {birthday}</p>
              <p className="text-xs mb-1">Faleceu em: {death}</p>
            </div>
          </div>
        </div>
        <div className="w-1/2 relative">
          <div
            className="text-center text-base mt-[40px]"
            dangerouslySetInnerHTML={{ __html: editalText }}
          />
          <div className="absolute bottom-0 right-0  mr-4">
            <ResponsiveImage
              src={funeralHomeURL}
              alt="logo"
              maxWidth="80"
              maxHeight="80"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme1;
