import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Region from "../Region"; // Import the Region component
import axios from "axios";

function Filter({
  currentPage,
  setPeople,
  setCurrentPage,
  setTotalPages,
  setFilterParams,
}) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRegion, setSelectedRegion] = useState({
    country: "",
    district: "",
    city: "",
    parish: "",
  });
  const [pageSize] = useState(12); // Number of items per page
  const [filterApplied, setFilterApplied] = useState(false); // Track if filter is applied

  const handleRegionChange = (key, value) => {
    setSelectedRegion((prevRegion) => ({
      ...prevRegion,
      [key]: value,
    }));
    setFilterParams((prevParams) => ({
      ...prevParams,
      [key]: value,
    }));
  };

  const handleApplyFilters = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_SERVER}filterPeople`,
        {
          params: {
            country: selectedRegion.country,
            district: selectedRegion.district,
            city: selectedRegion.city,
            name: searchTerm,
            parish: selectedRegion.parish,
            page: 1, // Reset to the first page after applying filters
            pageSize: pageSize,
          },
        }
      );
      const { data, totalPages } = response.data;
      setPeople(data); // Update the people state with filtered data
      setTotalPages(totalPages);
      setCurrentPage(1); // Reset current page to 1 after applying filters
      setShowModal(false);
      setFilterApplied(true);
      setFilterParams({
        country: selectedRegion.country,
        district: selectedRegion.district,
        city: selectedRegion.city,
        name: searchTerm,
        parish: selectedRegion.parish
      });
    } catch (error) {
      console.error("Error applying filters:", error);
    }
  };

  const handleClearFilter = () => {
    setCurrentPage(1); // Reset current page to 1
    setTotalPages(1); // Reset total pages to 1
    setFilterApplied(false); // Reset filter applied to false
    setSearchTerm(""); // Clear search term
    setSelectedRegion({
      country: "",
      district: "",
      city: "",
      parish: "",
    }); // Clear selected region
    setShowModal(false);
    window.location.reload();
  };

  return (
    <div>
      <button
        onClick={() => setShowModal(true)}
        className="bg-[#233957]  text-white px-4 py-2 rounded-md shadow-md mb-5 "
      >
        {t("filter.button")}
      </button>

      {/* Clear Filter button */}
      {filterApplied && (
        <button
          onClick={handleClearFilter}
          className="ml-2 bg-gray-300 text-gray-700 px-4 py-2 rounded-md shadow-md"
        >
          {t("filter.clearButton")}
        </button>
      )}

      {/* Modal dialog */}
      {showModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      {t("filter.modal.title")}
                    </h3>
                    <div className="mt-5">
                      {/* Region selector */}
                      <Region
                        country={selectedRegion.country}
                        district={selectedRegion.district}
                        city={selectedRegion.city}
                        parish={selectedRegion.parish}
                        onChange={(key, value) =>
                          handleRegionChange(key, value)
                        }
                        putParish={true}
                      />

                      {/* Name search */}
                      <div className="mt-4">
                        <label
                          htmlFor="search"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t("filter.modal.search")}
                        </label>
                        <input
                          type="text"
                          name="search"
                          id="search"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={handleApplyFilters}
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {t("filter.modal.apply")}
                </button>
                <button
                  onClick={() => setShowModal(false)}
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {t("filter.modal.cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Filter;
