import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";

export default function Tributes() {
  const [person, setPerson] = useState(null);
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPerson = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL_SERVER}getPersonbyId/${id}`
        );
        setPerson(response.data);
        if (Object.keys(response.data).length === 0) {
          navigate("/lastnews");
          window.location.reload();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPerson();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div>
      {person && (
        <div>
          <div className="container mx-auto mt-10 sm:mt-16 grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-16 pt-10 sm:pt-16 mb-8">
            <h2 className="lg:col-span-2 flex items-center justify-center text-3xl lg:text-4xl font-semibold text-gray-900">
              <Link to={`/person/${id}`} className="mr-2">
                <ChevronLeftIcon className="h-6 w-6" />
              </Link>
              <span>{t("tributes.title")}</span>
            </h2>
            <div className="lg:col-span-2 flex justify-center">
              <div className="grid grid-cols-2 gap-6">
                {Object.keys(person.tributes).map((key) => {
                  const tribute = person.tributes[key];
                  return (
                    <div
                      key={key}
                      className="flex flex-col max-w-xl items-start justify-between bg-white p-6 rounded-lg shadow-md"
                    >
                      <div className="flex items-center text-xs gap-x-4">
                        <time
                          dateTime={tribute.datetime}
                          className="text-gray-500"
                        >
                          {tribute.date}
                        </time>
                        <span className="relative z-10 bg-[#222831] px-3 py-1.5 rounded-md font-medium text-[#ffffff]">
                          {tribute.relationship}
                        </span>
                      </div>
                      <div className="group relative">
                        <p
                          className="mt-3 text-sm leading-5 text-gray-600"
                          style={{ display: "flex", alignItems: "flex-start" }}
                        >
                          {tribute.comment}
                        </p>
                      </div>
                      <div className="relative mt-6 flex items-center gap-x-4">
                        <p className="text-sm leading-5 font-semibold text-gray-900">
                          {tribute.author}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
