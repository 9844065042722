import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import TributeForm from "./TributeForm";
import ShareButtons from "../Socials/ShareButtons";
import { Helmet } from "react-helmet-async";
import { differenceInYears } from "date-fns";

export default function Person() {
  const [person, setPerson] = useState(null);
  const [funeralHome, setFuneralHome] = useState(null);
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchPersonAndFuneralHome = async () => {
      try {
        const personResponse = await axios.get(
          `${process.env.REACT_APP_URL_SERVER}getPersonbyId/${id}`
        );
        const personData = personResponse.data;
        setPerson(personData);

        // Fetch funeral home only when person data is available
        const funeralHomeResponse = await axios.get(
          `${process.env.REACT_APP_URL_SERVER}getFuneralHomeById/${personData.idFuneralHome}`
        );
        setFuneralHome(funeralHomeResponse.data);
        if (Object.keys(funeralHomeResponse.data).length === 0) {
          navigate("/lastnews");
          window.location.reload();
        }

        function reloadFacebookCache(url) {
          const uniqueParam = `refresh=${Date.now()}`;
          const accessToken = process.env.REACT_APP_FACEBOOK;
          const scrapeUrl = `https://graph.facebook.com/v19.0/?id=${encodeURIComponent(
            url
          )}&scrape=true&${uniqueParam}&access_token=${accessToken}`;

          fetch(scrapeUrl, {
            method: "POST",
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Failed to reload Facebook cache");
              }
              console.log("Facebook cache reloaded successfully");
              console.log(scrapeUrl);
            })
            .catch((error) => {
              console.error("Error reloading Facebook cache:", error);
            });
        }

        reloadFacebookCache("https://portaldasmemorias.com/person/" + id);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPersonAndFuneralHome();
  }, [id, navigate]); // Dependency only on id, since person is set within the function
  const url = `https://portaldasmemorias.com/person/${id}`;
  // Cálculo da idade

  return (
    <div>
      {person && (
        <div className="overflow-hidden bg-white py-24 sm:py-32">
          <Helmet defer={true}>
            <title>{person.name}</title>
            <meta name="description" content={person.description} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={person.name} />
            <meta property="og:description" content={person.description} />
            <meta property="og:image" content={person.thumbnail} />
            <meta property="og:url" content={url} />
            <link rel="canonical" href={url} />
            <meta property="og:site_name" content="Portal das Memórias" />
          </Helmet>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:pr-8 lg:pt-4">
                <div className="lg:max-w-lg">
                  <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {person.name}
                  </h2>
                  <div className="mt-2 inline-flex flex-wrap">
                    <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 ml-2">
                      {person.parish}, {person.city.replace(" Municipality", "")}, {person.district},{" "}
                      {person.country}
                    </span>
                    <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 ml-2">
                      {differenceInYears(
                        new Date(person.death),
                        new Date(person.birthday)
                      )}{" "}
                      {t("person.years")}
                    </span>
                    <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 ml-2">
                      {new Date(person.birthday)
                        .toLocaleDateString("en-GB")
                        .replace(/\//g, "-")}{" "}
                      -{" "}
                      {new Date(person.death)
                        .toLocaleDateString("en-GB")
                        .replace(/\//g, "-")}
                    </span>
                  </div>

                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    {person.description}
                  </p>
                  <ShareButtons
                    url={`https://portaldasmemorias.com/person/${id}`} // Replace with your actual URL
                    title={person ? person.name : ""} // Use person's name as the title
                  />
                </div>
              </div>
              <div className="flex justify-center items-center">
                <img
                  src={person.photo}
                  alt={person.name}
                  className="w-100 h-100 object-cover rounded-xl shadow-xl ring-1 ring-gray-400/10"
                />
              </div>
            </div>
            <p className="text-sm text-gray-500 mt-10">
              Atualizado em: {new Date(person.updated_at).toLocaleString()}
            </p>
          </div>
          <div class="mt-20 border-t border-gray-300 my-8"></div>

          <div className="overflow-hidden bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                <div class="lg:pr-8 lg:pt-4">
                  <div class="lg:max-w-lg flex items-center justify-center h-full">
                    <div class="mx-auto max-w-7xl px-6 lg:px-8">
                      <div class="mx-auto max-w-2xl lg:mx-0">
                        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                          <b>{t("person.info")}</b>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-1 grid grid-cols-1 md:grid-cols-1 gap-10">
                  <div className="bg-gray-100 p-4 rounded-lg shadow-md">
                    {person.funeralDetails?.celebration ? (
                      <div className="mt-2 bg-white p-4 rounded-md shadow-md flex items-start">
                        <img
                          src={`${process.env.REACT_APP_STORAGE}o/general%2Ficons8-church-80.png?alt=media&token=142caed3-947e-4804-a3dd-4c1dcedaf0c4`}
                          alt="Igreja"
                          className="w-16 h-16 mr-2"
                        />
                        <div className="flex flex-col justify-start">
                          <h1 className="text-left">
                            <b>{person.funeralDetails.celebration}</b>
                          </h1>
                          <div className="max-w-md text-justify">
                            <p>{person.funeralDetails.church.address} </p>
                            <p>
                              <b>{t("person.day")}: </b>
                              {person.funeralDetails?.church?.day
                                ? new Date(person.funeralDetails?.church?.day)
                                    .toLocaleDateString("en-GB")
                                    .replace(/\//g, "-")
                                : ""}
                            </p>
                            <p>
                              <b>{t("person.hour")}: </b>{" "}
                              {person.funeralDetails.church.time}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {person.funeralDetails?.funeral?.day ? (
                      <div className="mt-2 bg-white p-4 rounded-md shadow-md flex items-start">
                        <img
                          src={`${process.env.REACT_APP_STORAGE}o/general%2Ficons8-candle-50.png?alt=media&token=cf7c7ec7-a398-4eb4-a800-d1188ea9f3aa`}
                          alt="Igreja"
                          className="w-16 h-16 mr-2"
                        />
                        <div className="flex flex-col justify-start">
                          <h1 className="text-left">
                            <b>{t("register.personal-info.funeral.title")}</b>
                          </h1>
                          <div className="max-w-md text-justify">
                            <p>{person.funeralDetails.funeral.address}</p>
                            <p>
                              <b>{t("person.day")}: </b>
                              {person.funeralDetails?.funeral?.day
                                ? new Date(person.funeralDetails?.funeral?.day)
                                    .toLocaleDateString("en-GB")
                                    .replace(/\//g, "-")
                                : ""}
                            </p>
                            {/*     <p>
                            <b>{t("person.hour")} </b>{" "}
                            {person.funeralDetails.funeral.time}
                          </p> */}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Edital */}
                    {/*      <div className="mt-2 bg-white p-4 rounded-md shadow-md flex items-start">
                      <img
                        src={`${process.env.REACT_APP_STORAGE}o/general%2Ficons8-flower-100.png?alt=media&token=675bc2c6-477c-4826-ae54-61a241036a50`}
                        alt="Igreja"
                        className="w-16 h-16 mr-2"
                      />
                      <div className="flex flex-col justify-start">
                        <h1 className="text-left">
                          <b>{t("person.edital")} </b>
                        </h1>
                        <div className="flex flex-col justify-start">
                          <div className="max-w-md text-justify">
                            <p>{person.funeralDetails.funeral_services}</p>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    {person.funeralDetails?.additional_info ? (
                      <div className="mt-2 bg-white p-4 rounded-md shadow-md flex items-start">
                        <img
                          src={`${process.env.REACT_APP_STORAGE}o/general%2Ficons8-info-50.png?alt=media&token=71485f11-e6d7-4eec-8359-78fd143437d5`}
                          alt="Igreja"
                          className="w-16 h-16 mr-2"
                        />
                        <div className="flex flex-col justify-start">
                          <h1 className="text-left">
                            <b>{t("person.add-info")} </b>
                          </h1>
                          <div className="max-w-md text-justify">
                            <p>{person.funeralDetails.additional_info}</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {funeralHome && (
                      <div className="mt-2 bg-white p-4 rounded-md shadow-md flex items-start">
                        <img
                          src={funeralHome.photo}
                          alt="Igreja"
                          className="w-16 h-16 mr-2"
                        />
                        <div className="flex flex-col justify-start">
                          <h1 className="text-left">
                            <b>{funeralHome.name}</b>
                          </h1>
                          <div className="max-w-md text-justify">
                            <p></p>
                            <p>{funeralHome.phone}</p>
                            <p>{funeralHome.email}</p>
                            <p>
                              {funeralHome.address}, {funeralHome.city.replace(" Municipality", "")} ,
                              {funeralHome.country}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="border-t border-gray-300 my-8"></div>
          {/* Tributes Part */}
          <div className="container mx-auto mt-10 sm:mt-16 grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-16 pt-10 sm:pt-16">
            <h2 class="lg:col-span-2 text-3xl lg:text-4xl font-semibold text-center text-gray-900">
              {t("person.tributes")}
            </h2>
            <div className="lg:col-span-2 flex justify-center">
              <div className="grid grid-cols-2 gap-6">
                {Object.keys(person.tributes)
                  .slice(0, 2)
                  .map((key) => {
                    const tribute = person.tributes[key];
                    return (
                      <div
                        key={key}
                        className="flex flex-col max-w-xl items-start justify-between bg-white p-6 rounded-lg shadow-md"
                      >
                        <div className="flex items-center text-xs gap-x-4">
                          <time
                            dateTime={tribute.datetime}
                            className="text-gray-500"
                          >
                            {tribute.date}
                          </time>
                          <span className="relative z-10 bg-[#222831] px-3 py-1.5 rounded-md font-medium text-[#ffffff]">
                            {tribute.relationship}
                          </span>
                        </div>
                        <div className="group relative">
                          <p className="mt-3 line-clamp-3 text-sm leading-5 text-gray-600">
                            {tribute.comment}
                          </p>
                        </div>
                        <div className="relative mt-6 flex items-center gap-x-4">
                          <p className="text-sm leading-5 font-semibold text-gray-900">
                            {tribute.author}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <Link
              to={`/tributes/${id}`}
              className="mt-5 flex items-center justify-center rounded-md border border-transparent bg-[#384C67] px-6 py-2 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              {t("home.seeall")}
            </Link>
          </div>
          <TributeForm personId={id} name={person.name} />
        </div>
      )}
    </div>
  );
}
